<template>
  <div>
    <b-row>
      <b-col
        md="7"
        lg="5"
        class="mt-5 mx-auto"
        data-aos="fade-left"
        v-if="success"
      >
        <b-form @submit.prevent="onSubmit" id="set-password">
          <div class="content">
            <h4>{{ labels.greeting }}</h4>
            <p>{{ labels.info }}</p>
          </div>
          <b-form-group :class="{ formLoading: isLoading }">
            <md-field>
              <label>{{ labels.password }}</label>
              <md-input
                type="password"
                name="password"
                v-model="form.password"
                :placeholder="labels.password"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.secondpassword }}</label>
              <md-input
                type="password"
                name="password"
                v-model="form.secondpassword"
                :placeholder="labels.secondpassword"
                required
              />
            </md-field>
            <b-button type="submit" id="login-btn">{{ labels.login }}</b-button>
            <a href="#" @click.prevent="changeLanguage()">{{ labels.lang }}</a>
          </b-form-group>
          <div class="loadingSpinner" :class="{ active: isLoading }">
            <b-spinner></b-spinner>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Auth from "@/services/Authentication";

export default {
  data: () => ({
    form: {
      email: "",
      password: "",
      secondpassword: "",
    },
    success: false,
    labels: "",
    isLoading: false,
    error: false,
  }),
  methods: {
    onSubmit() {
      this.isLoading = true;
      Auth.setPassword(this.form)
        .then(() => {
          this.error = false;
          this.isLoading = false;
          this.$swal(this.labels.alertTitle, this.labels.alertMessage, "success")
          .then(() => {
           this.$router.push({ name: "login" });}
          )          
        })
        .catch(() => {
          this.error = true;
          this.isLoading = false;
        });
    },
    changeLanguage() {
      this.isLoading = true;
      if (this.$store.state._language == "en-US") {
        this.$store.commit("setLang", "es-ES");
        this.labels = {
          greeting: "Hola!",
          info: "Diligencie sus datos para ingresar",
          email: "Contraseña",
          password: "Repite tu Contraseña",
          login: "Ingresar",
          lang: "English Version",
          alertTitle: "Configuración Exitosa",
        alertMessage: "Ahora puedes iniciar sesión"

        };
      } else {
        this.$store.commit("setLang", "en-US");
        this.labels = {
          greeting: "Hello!",
          info: "Fill the flieds to continue",
          email: "Password",
          password: "Repeat Password",
          login: "Login",
          lang: "Versión en Español",
          alertTitle: "Successfull Cnfiguration",
        alertMessage: "Now you can login"
        };
      }
      this.isLoading = false;
    },
  },
  beforeCreate() {
    Auth.validate(this.$route.query.t)
      .then((data) => {
        const now = new Date();
        const exp = new Date(data.data.expiration);
        const diff = exp.getTime() - now.getTime();
        if (diff <= 0) {
          this.$router.push({ name: "login" });
        } else {
          this.form.email = data.data.email;
          this.success = true;
        }
      })
      .catch(() => {
        this.$router.push({ name: "login" });
      });
  },
  mounted() {
    if (this.$store.state._language == "es-ES") {
      this.labels = {
        greeting: "Hola!",
        info: "Configura tu nueva contraseña",
        password: "Contraseña",
        secondpassword: "Repite tu Contraseña",
        login: "Configurar",
        lang: "English Version",
        alertTitle: "Configuración Exitosa",
        alertMessage: "Ahora puedes iniciar sesión"
      };
    } else {
      this.labels = {
        greeting: "Hello!",
        info: "FSet up your new password",
        password: "Password",
        secondpassword: "Repeat Password",
        login: "Set",
        lang: "Versión en Español",
        alertTitle: "Successfull Cnfiguration",
        alertMessage: "Now you can login"
      };
    }
  },
};
</script>

<style src="../main.css"></style>
