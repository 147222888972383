<template>
  <div>
    <b-row>
      <b-container>
        <h1 class="text-center py-2">
          {{ labels.title }}
        </h1>
      </b-container>
    </b-row>
    <b-row>
      <b-container>
        <h4 class="text-center border-bottom">
          {{ labels.subtitle }}
        </h4>
        <b-col md="8" class="mx-auto">
          <ul>
            <li v-for="(item, idx) in labels.instructive" :key="idx">
              {{ item }}
            </li>
          </ul>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-if="!isLoading">
      <b-container v-if="!success && !error">
        <b-form @submit.prevent="submitForm">
          <h4 class="text-center border-bottom">
            {{ labels.info }}
          </h4>
          <b-col md="8" lg="8" class="p-4 mx-auto">
            <b-form-group>
               <md-field v-if="$store.state.userRole == 'COM'">
              <md-select v-model="form.companyId" :placeholder="labels.companyName">
                <md-option v-for="item in companies" :value="item.id" :key="item.id">{{item.name}}</md-option>
              </md-select>
            </md-field>
              <md-field>
                <label>{{ labels.services }}</label>
                <!-- <span><font-awesome-icon :icon="['fas', 'sign-out-alt']"/></span> -->
                <md-input
                  type="text"
                  name="services"
                  v-model="form.services"
                  required
                />
              </md-field>
            </b-form-group>
            <b-form-row>
              <b-col>
                <md-radio v-model="form.type" value="Natural">{{
                  labels.natural
                }}</md-radio>
              </b-col>
              <b-col>
                <md-radio v-model="form.type" value="Juridica">{{
                  labels.legal
                }}</md-radio>
              </b-col>
            </b-form-row>
          </b-col>
          <h4 class="text-center border-bottom">
            {{ labels.basicData }}
          </h4>
          <b-col md="10" lg="8" class="p-4 mx-auto">
            <div v-if="form.type == 'Juridica'">
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <md-field>
                      <label for="j-type">{{ labels.entityType }}</label>
                      <md-select
                        v-model="form.entityType"
                        name="j-type"
                        id="j-type"
                        required
                      >
                        <md-option value="private">{{
                          labels.entityTypes[0]
                        }}</md-option>
                        <md-option value="public">{{
                          labels.entityTypes[1]
                        }}</md-option>
                        <md-option value="mixed">{{
                          labels.entityTypes[2]
                        }}</md-option>
                      </md-select>
                    </md-field>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <md-field>
                      <label>{{ labels.nit }}</label>
                      <md-input
                        v-model="form.nit"
                        type="text"
                        required
                      ></md-input>
                    </md-field>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group>
                    <md-field>
                      <label>{{ labels.social }}</label>
                      <md-input
                        v-model="form.social"
                        type="text"
                        required
                      ></md-input>
                    </md-field>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <md-field>
                      <label>{{ labels.legalName }}</label>
                      <md-input
                        v-model="form.legal"
                        type="text"
                        required
                      ></md-input>
                    </md-field>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </div>
            <div v-else>
              <b-form-group>
                <md-field>
                  <label>{{ labels.name }}</label>
                  <md-input v-model="form.name" type="text" required></md-input>
                </md-field>
              </b-form-group>
            </div>
            <b-form-row>
              <b-col md="4">
                <b-form-group>
                  <md-field>
                    <label for="j-idtype">{{ labels.idType }}</label>
                    <md-select
                      v-model="form.idType"
                      name="j-idtype"
                      id="j-idtype"
                      required
                    >
                      <md-option value="cc">CC</md-option>
                      <md-option value="ce">CE</md-option>
                    </md-select>
                  </md-field>
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group>
                  <md-field>
                    <label>{{ labels.idNumber }}</label>
                    <md-input
                      v-model="form.idNumber"
                      type="text"
                      required
                    ></md-input>
                  </md-field>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="8">
                <b-form-group>
                  <md-field>
                    <label>{{ labels.address }}</label>
                    <md-input
                      v-model="form.address"
                      type="text"
                      required
                    ></md-input>
                  </md-field>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <md-field>
                    <label>{{ labels.phone }}</label>
                    <md-input
                      v-model="form.phone"
                      type="number"
                      required
                    ></md-input>
                  </md-field>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group>
                  <md-field>
                    <label>{{ labels.ciiuCode }}</label>
                    <md-input
                      v-model="form.ciiuCode"
                      type="text"
                      required
                    ></md-input>
                  </md-field>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <md-field>
                    <label>{{ labels.email }}</label>
                    <md-input
                      v-model="form.email"
                      type="email"
                      name="email"
                      required
                    ></md-input>
                  </md-field>
                </b-form-group>
              </b-col>
            </b-form-row>
            <!-- <b-button type="submit" id="login-btn">Ingresar</b-button> -->
          </b-col>

          <h4 class="text-center border-bottom">
            {{ labels.financialInfo }}
          </h4>
          <b-col md="10" lg="8" class="p-4 mx-auto">
            <b-form-group>
              <md-field>
                <label>{{ labels.income }}</label>
                <md-input
                  v-model="form.income"
                  type="number"
                  required
                ></md-input>
              </md-field>
            </b-form-group>
            <b-form-group>
              <md-field>
                <label>{{ labels.outcome }}</label>
                <md-input
                  v-model="form.outcome"
                  type="number"
                  required
                ></md-input>
              </md-field>
            </b-form-group>
            <b-form-group>
              <md-field>
                <label>{{ labels.active }}</label>
                <md-input
                  v-model="form.active"
                  type="number"
                  required
                ></md-input>
              </md-field>
            </b-form-group>
            <b-form-group>
              <md-field>
                <label>{{ labels.pasive }}</label>
                <md-input
                  v-model="form.pasive"
                  type="number"
                  required
                ></md-input>
              </md-field>
            </b-form-group>
            <b-form-group>
              <md-field>
                <label>{{ labels.otherIncome }}</label>
                <md-input
                  v-model="form.otherIncome"
                  type="number"
                  required
                ></md-input>
              </md-field>
            </b-form-group>
          </b-col>
          <div v-if="form.type == 'Juridica'">
            <h4 class="text-center border-bottom">
              {{ labels.shareholders }}
            </h4>
            <b-col md="10" lg="10" class="p-4 mx-auto">
              <b-form-row v-for="(item, idx) in form.shareholders" :key="idx">
                <b-col md="6" lg="4">
                  <b-form-group>
                    <md-field>
                      <label>{{ labels.shareholdername }}</label>
                      <md-input
                        v-model="item.name"
                        type="text"
                        required
                      ></md-input>
                    </md-field>
                  </b-form-group>
                </b-col>
                <b-col md="2" lg="2">
                  <b-form-group>
                    <md-field>
                      <label for="sh-type">{{ labels.shareholdertype }}</label>
                      <md-select
                        v-model="item.type"
                        name="sh-type"
                        id="sh-type"
                        required
                      >
                        <md-option value="cc">CC</md-option>
                        <md-option value="ce">CE</md-option>
                      </md-select>
                    </md-field>
                  </b-form-group>
                </b-col>
                <b-col md="4" lg="3">
                  <b-form-group>
                    <md-field>
                      <label>{{ labels.shareholdernumber }}</label>
                      <md-input
                        v-model="item.number"
                        type="text"
                        required
                      ></md-input>
                    </md-field>
                  </b-form-group>
                </b-col>
                <b-col sm="6" lg="2">
                  <b-form-group>
                    <md-field>
                      <label>{{ labels.shareholderpercentage }}</label>
                      <md-input
                        v-model="item.percentage"
                        type="number"
                        required
                      ></md-input>
                    </md-field>
                  </b-form-group>
                </b-col>
                <b-col sm="6" lg="1" class="remove">
                  <md-button
                    class="md-icon-button md-accent "
                    @click="removeRow(idx)"
                    ><font-awesome-icon :icon="['fas', 'minus']"
                  /></md-button>
                </b-col>
              </b-form-row>
              <md-button
                class="md-icon-button md-raised md-primary "
                @click="addRow"
                ><font-awesome-icon :icon="['fas', 'plus']"
              /></md-button>
            </b-col>
          </div>
          <h4 class="text-center border-bottom">
            {{ labels.legalInfo }}
          </h4>
          <b-col md="8" lg="8" class="p-4 mx-auto">
            <b-form-row>
              <b-col md="8">
                <!-- <b-form-group > -->
                  
                <md-field>
                  <label for="j-idtype">{{ labels.legalType }}</label>
                  <md-select
                    v-model="form.tributaryType"
                    name="j-idtype"
                    id="j-idtype"
                    required
                  >
                    <md-option
                      v-for="(item, idx) in labels.legalTypes"
                      :value="idx + 1"
                      :key="idx"
                      >{{ item }}</md-option
                    >
                  </md-select>
                </md-field>
                <!-- </b-form-group> -->
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <md-switch v-model="form.selfRetainer">{{
                    labels.selfRetainer
                  }}</md-switch>
                </b-form-group>
              </b-col>
            </b-form-row>
            <p>
              {{ labels.termsMessage }}
              <a href="" class="dialog" @click.prevent="showDialog = true">{{
                labels.terms.title
              }}</a>
            </p>
            <md-dialog :md-active.sync="showDialog">
              <md-dialog-title>{{ labels.terms.title }}</md-dialog-title>
              <md-dialog-content>
                <md-tabs md-dynamic-height>
                  <md-tab
                    :md-label="item.label"
                    v-for="(item, idx) in labels.terms.parts"
                    :key="idx"
                  >
                    <h5>{{ item.title }}</h5>
                    <p v-for="(text, index) in item.content" :key="index">
                      {{ text }}
                    </p>
                  </md-tab>
                </md-tabs>
              </md-dialog-content>

              <md-dialog-actions>
                <md-button class="md-primary" @click="showDialog = false"
                  >OK</md-button
                >
              </md-dialog-actions>
            </md-dialog>

            <b-button type="submit" id="signup-btn">{{
              labels.button
            }}</b-button>
          </b-col>
        </b-form>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div
            class="success-svg mb-4"
            :class="{ active: success }"
            v-if="!error"
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 496 496"
              style="enable-background:new 0 0 496 496;"
              xml:space="preserve"
            >
              <path
                d="M248,0C111.033,0,0,111.033,0,248s111.033,248,248,248s248-111.033,248-248C495.841,111.099,384.901,0.159,248,0z
                     M248,480C119.87,480,16,376.13,16,248S119.87,16,248,16s232,103.87,232,232C479.859,376.072,376.072,479.859,248,480z"
                style="fill: #00ad7e"
              />
              <path
                d="M370.344,158.344L208,320.688l-82.344-82.344c-3.178-3.07-8.242-2.982-11.312,0.196c-2.994,3.1-2.994,8.015,0,11.116
                    l88,88c3.124,3.123,8.188,3.123,11.312,0l168-168c3.07-3.178,2.982-8.242-0.196-11.312
                    C378.359,155.35,373.444,155.35,370.344,158.344z"
                style="fill: #00ad7e"
              />
            </svg>
          </div>
          <div class="success-svg mb-4" :class="{ active: error }" v-else>
            <svg
              xmlns:dc="http://purl.org/dc/elements/1.1/"
              xmlns:cc="http://creativecommons.org/ns#"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 52 52"
              style="enable-background:new 0 0 52 52;"
              xml:space="preserve"
              sodipodi:docname="error.svg"
              inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
            >
              <metadata id="metadata43">
                <rdf:RDF
                  ><cc:Work rdf:about=""
                    ><dc:format>image/svg+xml</dc:format
                    ><dc:type
                      rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title></dc:title></cc:Work
                ></rdf:RDF>
              </metadata>
              <defs id="defs41" />
              <sodipodi:namedview
                pagecolor="#ffffff"
                bordercolor="#666666"
                borderopacity="1"
                objecttolerance="10"
                gridtolerance="10"
                guidetolerance="10"
                inkscape:pageopacity="0"
                inkscape:pageshadow="2"
                inkscape:window-width="1920"
                inkscape:window-height="1013"
                id="namedview39"
                showgrid="false"
                inkscape:zoom="4.5384615"
                inkscape:cx="26"
                inkscape:cy="26"
                inkscape:window-x="-9"
                inkscape:window-y="-9"
                inkscape:window-maximized="1"
                inkscape:current-layer="Capa_1"
              />
              <g id="g6" style="fill:#ee6363;fill-opacity:0.94117647">
                <path
                  d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26   S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
                  id="path2"
                  style="fill-opacity:0.94117647"
                />
                <path
                  d="M35.707,16.293c-0.391-0.391-1.023-0.391-1.414,0L26,24.586l-8.293-8.293c-0.391-0.391-1.023-0.391-1.414,0   s-0.391,1.023,0,1.414L24.586,26l-8.293,8.293c-0.391,0.391-0.391,1.023,0,1.414C16.488,35.902,16.744,36,17,36   s0.512-0.098,0.707-0.293L26,27.414l8.293,8.293C34.488,35.902,34.744,36,35,36s0.512-0.098,0.707-0.293   c0.391-0.391,0.391-1.023,0-1.414L27.414,26l8.293-8.293C36.098,17.316,36.098,16.684,35.707,16.293z"
                  id="path4"
                  style="fill-opacity:0.94117647"
                />
              </g>
              <g id="g8"></g>
              <g id="g10"></g>
              <g id="g12"></g>
              <g id="g14"></g>
              <g id="g16"></g>
              <g id="g18"></g>
              <g id="g20"></g>
              <g id="g22"></g>
              <g id="g24"></g>
              <g id="g26"></g>
              <g id="g28"></g>
              <g id="g30"></g>
              <g id="g32"></g>
              <g id="g34"></g>
              <g id="g36"></g>
            </svg>
          </div>
        </b-col>
        <b-col md="12" class="justify-content-around">
          <span
            type="button"
            id="reset-btn"
            v-if="success"
            @click="resetForm()"
            >{{ labels.new }}</span
          >
          <span
            type="button"
            id="check-btn"
            v-if="error"
            @click="checkForm()"
            >{{ labels.validate }}</span
          >
          <span
            type="button"
            id="resend-btn"
            v-if="error"
            @click="submitForm()"
            >{{ labels.try }}</span
          >
        </b-col>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Register from "@/services/Register";
import Company from "@/services/Company";

export default {
  name: "Register",

  data: () => ({
     form: {
      companyId: "",
      services: "",
      entityType: "",
      nit: "",
      social: "",
      legal: "",
      name: "",
      idType: "",
      idNumber: "",
      address: "",
      phone: "",
      ciiuCode: "",
      email: "",
      tributaryType: "",
      selfRetainer: false,
      type: "Juridica",
      income: "",
      outcome: "",
      active: "",
      pasive: "",
      otherIncome: "",
      shareholders: [
        {
          name: "",
          type: "",
          number: "",
          percentage: "",
        },
      ],
    },
    isLoading: false,
    success: false,
    error: false,
    showDialog: false,
    companies: []
  }),
  methods: {
    addRow() {
      this.form.shareholders.push({
        name: "",
        type: "",
        number: "",
        percentage: "",
      });
    },
    removeRow(idx) {
      this.form.shareholders.splice(idx, 1);
    },
    submitForm: function() {
      this.isLoading = true;
      this.form.language = this.$store.state._language
      if(!this.form.companyId){
        this.form.companyId = this.$store.state.companyId
      }
      Register.send(this.form)
        .then(() => {
          this.error = false;
          if (
            this.$store.state.userRole == "ADM" ||
            this.$store.state.userRole == "COM"
          ) {
            if (this.$route.name == "registration") {
                this.$swal(this.labels.alertTitle, this.labels.registroMessage, "success")
                const obj = {
                      token: "",
                      username: "",
                      role: "",
                      expiresIn: "",
                      isAuthenticated: false,
                    };
                    this.$store.commit("setData", obj);
                    this.$router.push("/");
            }
            this.success = true;
          } else {
            this.success = true;
            this.$swal(this.labels.alertTitle, this.labels.alertMessage, "success")
          .then(() => {
           this.$router.push({ name: "login" });}
          )       
          }
        })
        .catch(() => {
          // this.success = true;
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
      
    },
    resetForm: function(e) {
      this.form.services = "";
      this.form.name = "";
      this.form.entityType = "";
      this.form.nit = "";
      this.form.social = "";
      this.form.legal = "";
      this.form.idType = "";
      this.form.idNumber = "";
      this.form.address = "";
      this.form.phone = "";
      this.form.ciiuCode = "";
      this.form.email = "";
      this.form.tributarytype = null;
      this.form.selfRetainer = false;
      this.form.type = "Juridica";
      this.form.income = "";
      this.form.outcome = "";
      this.form.active = "";
      this.form.pasive = "";
      this.form.otherIncome = "";
      this.form.shareholders = [
        {
          name: "",
          type: "",
          number: "",
          percentage: "",
        },
      ];
      this.success = false;

      e.preventDefault();
    },
    checkForm: function() {
      this.error = false;
    },
  },
  mounted(){
    if(this.$store.state.userRole == "COM" ){
    this.isLoading = true;
    Company.getAllCompanies()
      .then((result) => {
        // console.log(result.data)
        this.companies = result.data.companies;
        if (this.companies.length == 0) {
          this.isEmpty = true;
        }
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      })
      .finally(() => {
        this.isLoading = false;   
      });
    }
  },
  beforeCreate() {
    this.isLoading = true;
    if (this.$route.name == "registration") {
      Register.validate(this.$route.query.t)
        .then((data) => {
          const now = new Date();
          const exp = new Date(data.data.expiration);
          const diff = exp.getTime() - now.getTime();
          if (diff <= 0) {
            this.$router.push({ name: "login" });
          } else {
            this.form.email = data.data.email;
            this.form.companyId = data.data.companyId
          }
        })
        .catch(() => {
          this.$router.push({ name: "login" });
        });
    }
    this.isLoading = false;

  },
  computed: {
    labels() {
      if (this.$store.state._language == "es-ES") {
        return {
          title: "Formulario de inscripción",
          subtitle: "Instrucciones",
          instructive: [
            "Por favor diligencie totalmente el contenido de este formato.  Una vez finalizado, enviaremos en el trascurso de 24 horas al correo registrado una clave de acceso de uso de la herramienta de radicación",
            "En esta herramienta es el medio habilitado para la radicación de sus facturas.",
            "Este formulario lo deberá diligenciar una única vez, a menos que se presenten cambios importantes en la información que contiene como la calidad tributaria, domicilio, contacto entre otros, caso en el cual deberá diligenciarlo nuevamente para pedir una nueva clave de acceso.",
          ],
          info: "Información Basica",
          services: "Bienes y/o Servicios",
          natural: "Persona Natural",
          legal: "Persona Jurídica",
          basicData: "Datos Básicos",
          entityType: "Tipo Entidad",
          entityTypes: ["Privada", "Pública", "Mixta"],
          nit: "NIT",
          social: "Razón Social",
          legalName: "Nombre Representante Legal",
          name: "Nombre Completo",
          idType: "Tipo de Identificación",
          idNumber: "Número de identificación",
          address: "Dirección",
          phone: "Teléfono",
          ciiuCode: "Código CIIU",
          email: "Correo Electrónico",
          financialInfo: "Información Financiera",
          income: "Ingresos a 31 de diciembre del año anterior",
          outcome: "Egresos al 31 de diciembre del año anterior",
          active: "Total activos al 31 de diciembre del año anterior",
          pasive: "Total pasivos al 31 de diciembre del año anterior",
          otherIncome:
            "Otros ingresos generados en actividades diferentes a la principal",
          shareholders:
            "Información Socios o Accionistas con Participación Superior al 5%",
          shareholdername: "Nombre",
          shareholdertype: "Tipo",
          shareholdernumber: "Número",
          shareholderpercentage: "%-Participación",
          legalInfo: "Información Tributaria",
          legalType: "Tipo de Contribuyente",
          legalTypes: [
            "Gran Contribuyente",
            "Responsable de IVA",
            "No Reesponsable de IVA",
            "Régimen Simple de Tributación",
            "Entidad Sin Ánimo de Lucro",
            "Persona Natural Declarante del Impuesto a la  Renta",
          ],
          selfRetainer: "Auto Retenedor",
          button: "Enviar",
          validate: "Revisar Formulario",
          new: "Diligenciar nuevo formulario",
          salir: "Finalizar Registro",
          try: "Volver a Intentar",
          alertTitle: "Registro Exitoso!",
          alertMessage: "Revisa tu correo para continuar",
          registroMessage: "Hemos enviado un correo de confirmacion",
          termsMessage: "Al presionar el botón de enviar está aceptando",
          terms: {
            title: "Términos y Condiciones",
            parts: [
              {
                label: "Parte 1",
                title:
                  "DECLARACIÓN DE ORIGEN DE FONDOS, RECURSOS, BIENES Y/O SERVICIOS",
                content: [
                  "El proveedor declara que su negocio y los recursos que utilizará para la ejecución del objeto de su Registro como Proveedor de PRUEBA FACTURAS S.A.S. , no provienen de  ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo modifique o lo adicione.",
                ],
              },
              {
                label: "Parte 2",
                title: "DECLARACIÓN DE LISTAS RESTRICTIVAS",
                content: [
                  "FCPA – Ley de Prácticas Corruptas en el Extranjero",
                  "Declaro que en relación con los bienes o servicios, nosotros no efectuaremos con conocimiento, ni induciremos a que un tercero efectúe un Pago Prohibido. Además, exigiremos de cualquier subcontratista que tenga contratado para asesorar en la prestación de los Servicios o venta de bienes que acuerde no efectuar a sabiendas un Pago Prohibido, ni que haga que un tercero lo efectúe. ",
                  "Definiciones. Para los fines de esta sección, los términos que aparecen a continuación tendrán el siguiente significado: ",
                  ' "Pago Prohibido" significará el pago, oferta o promesa de pagar, o autorización de pago, oferta o promesa, directa o indirectamente (a través de uno o más intermediarios), de cualquier dinero o algo de valor a: (i) cualquier funcionario público con el fin de ejercer influencia sobre cualquier acto o decisión, o para obtener cualquier ventaja inapropiada de dicho funcionario público,  agencia gubernamental o partido político, con el fin de obtener o retener negocios, para o con, cualquier persona u orientar negocios hacia ella; o (ii) cualquier otra persona o entidad, si dicho pago, oferta, promesa o autorización violara la FCPA. ',
                  '"Organización Pública Internacional" significará cualquier organización pública internacional cubierta por la FCPA, incluidas las instituciones financieras internacionales, tales como el Grupo del Banco Mundial, el Banco para la Reconstrucción & Desarrollo, el Banco Europeo para la Reconstrucción y Desarrollo y el Banco de Desarrollo Asiático.',
                  '"Funcionario Público” significará cualquier funcionario o empleado de un gobierno o cualquier departamento, agencia o medio de éstos, o de una organización pública internacional, cualquier partido político, cualquier funcionario de un partido político, cualquier candidato a un cargo político, o cualquier persona que actúe en calidad o en nombre de dicho gobierno, departamento, agencia, medio, organización pública internacional o partido político. ',
                  "Así mismo declaro que ni como persona natural ni como persona jurídica, mis accionistas o similares, gerentes o directivos:  i) se encuentran incluidos en alguna de las listas para el control de lavado de activos y financiación del terrorismo administradas por cualquier autoridad nacional o extranjera, ii) hemos sido formalmente acusados ante un Juez por delitos relacionados con lavado de activos o financiación del terrorismo, iii) no hemos incumplido las normas relativas a la prevención de lavado de activos y financiación al terrorismo, estando obligados por ley, iv) no formarmos parte de las listas restrictivas nacionales o del extranjero. ",
                ],
              },
              {
                label: "Parte 3",
                title: "CERTIFICADO DE ORIGEN DE LAS MATERIAS PRIMAS",
                content: [
                  "PRUEBA FACTURAS se reserva el derecho de solicitar en cualquier momento los respectivos certificados de origen de las materias primas suministradas por el proveedor y el proveedor se compromete a entregarlo oportunamente a fin de continuar con su relación de proveedor con la empresa. ",
                ],
              },
            ],
          },
          companyName: "Compañia"
        };
      } else {
        return {
          title: "Sign up Form Platform ",
          subtitle: "Instructions",
          instructive: [
            "Please fill out this form completely. Once completed, we will send within 24 hours to the registered email a password for the use of the settlement tool.",
            "This tool is the means enabled for the settlement of your invoices.",
            "This form must be filled out only once, unless there are significant changes in the information it contains, such as tax quality, address, contact, among others, in which case it must be filled out again to request a new password.",
          ],
          info: "Basic Information",
          services: "Services and/or goods",
          natural: "Natural Person",
          legal: "Legal Person",
          basicData: "Basic Data",
          entityType: "Entity Type",
          entityTypes: ["Private", "Public", "Mixed"],
          nit: "NIT",
          social: "Business Name",
          legalName: "Name of Legal Representative",
          name: "Full Name",
          idType: "Identification Type",
          idNumber: "Number",
          address: "Address",
          phone: "Phone number",
          ciiuCode: "CIIU Code",
          email: "Email",
          financialInfo: "Financial Information",
          income: "Income as of December 31st of the previous year",
          outcome: "Expenses as of December 31st of the previous year",
          active: "Total assets as of December 31 of the previous year",
          pasive: "Total liabilities as of December 31 of the previous year",
          otherIncome:
            "Other income generated in activities other than the main one",
          shareholders:
            "Information on Partners or Shareholders with a Participation of more than 5%.",
          shareholdername: "Name",
          shareholdertype: "Type",
          shareholdernumber: "Number",
          shareholderpercentage: "Participation-%",
          legalInfo: "Legal Information",
          legalType: "Legal Type",
          legalTypes: [
            "Great Contributor",
            "Responsible for VAT",
            "Not Responsible for VAT",
            "Simple Taxation Regime",
            "Non-Profit Entity",
            "Natural Person Declaring Income Tax",
          ],
          selfRetainer: "Self Retainer",
          button: "Send",
          validate: "Check Invoice Form",
          new: "Sign New Provider",
          salir: "Finish Registration",
          located: "Settle Number",
          try: "Try Again",
          alertTitle: "Successfull registration!",
          alertMessage: "Check your email to continue",
          termsMessage: "By pressing the send button you are accepting",
          terms: {
            title: "Terms and Conditions",
            parts: [
              {
                label: "1st Part",
                title:
                  "DECLARATION OF ORIGIN OF FUNDS, RESOURCES, GOODS AND/OR SERVICES",
                content: [
                  "The supplier declares that his business and the resources he will use for the execution of the object of his registration as a PRUEBA FACTURAS S.A.S. supplier do not come from any illicit activity of those contemplated in the Colombian Penal Code or in any norm that modifies or adds to it.",
                ],
              },
              {
                label: "2nd Part",
                title: "DECLARATION OF RESTRICTIVE LISTS",
                content: [
                  "FCPA - Foreign Corrupt Practices Act",
                  "I declare that in connection with the goods or services, we will not knowingly make, or induce a third party to make, a Prohibited Payment. In addition, we will require any subcontractor you have retained to advise on the provision of the Services or sale of goods to agree not to knowingly make a Prohibited Payment, or to cause a third party to make a Prohibited Payment. ",
                  "Definitions. For purposes of this section, the terms below shall have the following meanings: ",
                  ' "Prohibited Payment"" shall mean the payment, offer or promise to pay, or authorization of payment, offer or promise, directly or indirectly (through one or more intermediaries), of any money or anything of value to: (i) any public official for the purpose of exerting influence over any act or decision, or for obtaining any improper advantage from such public official, government agency or political party, in order to obtain or retain business for or with any person or to direct business towards such person; or (ii) any other person or entity, if such payment, offer, promise or authorization would violate the FCPA. ',
                  '"Public International Organization" shall mean any public international organization covered by the FCPA, including international financial institutions such as the World Bank Group, the Bank for Reconstruction & Development, the European Bank for Reconstruction and Development and the Asian Development Bank.',
                  '"Public Official" shall mean any officer or employee of a government or any department, agency, or instrumentality thereof, or of a public international organization, any political party, any official of a political party, any candidate for political office, or any person acting in the capacity or on behalf of such government, department, agency, instrumentality, public international organization, or political party. ',
                  "I also declare that neither as a natural person nor as a legal entity, my shareholders or similar, managers or directors: i) are included in any of the lists for the control of money laundering and financing of terrorism administered by any national or foreign authority, ii) have been formally charged before a judge for crimes related to money laundering or financing of terrorism, iii) have not failed to comply with the rules relating to the prevention of money laundering and financing of terrorism, being obliged by law, iv) are not part of the national or foreign restrictive lists.",
                ],
              },
              {
                label: "3rd Part",
                title: "CERTIFICATE OF ORIGIN OF RAW MATERIALS",
                content: [
                  "PRUEBA FACTURAS reserves the right to request at any time the respective certificates of origin of the raw materials supplied by the supplier and the supplier agrees to deliver them in a timely manner in order to continue its supplier relationship with the company. ",
                ],
              },
            ],
          },
          companyName: "Company Name"
        };
      }
    },
  },
};
</script>

<style src="../main.css">
form {
  width: 100%;
}
</style>
