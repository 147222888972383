<template>
  <div class="py-0" id="sidebar">
    <!-- <b-container-fluid> -->
    <ul>
      <li>
        <a href="#">
          <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" />
          <span>Complement</span>
        </a>
      </li>
      <li
        v-for="(item, idx) in pages"
        :key="idx"
        :class="{ 'active-page': $route.name == item.route }"
      >
        <a :href="item.ref">
          <font-awesome-icon :icon="['fas', item.icon]" />
          <span>{{ item.name }}</span>
        </a>
      </li>
    </ul>
    <!-- </b-container-fluid> -->
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      menu: "",
      home: "/#home",
      scroll: false,
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.scrollFunction);
  },
  methods: {
    scrollFunction(e) {
      this.scroll = window.pageYOffset > 300;
      e.preventDefault();
    },
    logout: function() {
      const obj = {
        token: "",
        username: "",
        role: "",
        expiresIn: "",
        isAuthenticated: false,
      };
      this.$store.commit("setData", obj);
      this.$router.push("/");
    },
    toggleLanguage() {
      if (this.$store.state._language == "es-ES") {
        this.$store.commit("setLang", "en-US");
      } else {
        this.$store.commit("setLang", "es-ES");
      }
    },
  },
  computed: {
    role() {
      // console.log(this.$store.state.userRole)
      return this.$store.state.userRole;
    },
    isAuthenticated() {
      // console.log(this.$store.state.isAuthenticated)
      return this.$store.state.isAuthenticated;
    },
    language() {
      // console.log(this.$store.state._language)
      return this.$store.state._language;
    },
    labels() {
      if (this.$store.state._language == "es-ES") {
        return {
          pages: [
            {
              name: "Dashboard",
              ref: "/home",
              route: "home",
              roles: ["ADM", "COM"],
              icon: "home",
            },
            {
              name: "Empresas",
              ref: "/companies",
              route: "companies",
              roles: [ "COM"],
              icon: "building",
            },
            {
              name: "Usuarios",
              ref: "/users",
              route: "users",
              roles: ["ADM", "COM"],
              icon: "users",
            },
            {
              name: "Invitar",
              ref: "/invite",
              route: "invite",
              roles: ["ADM", "COM"],
              icon: "paper-plane",
            },
            {
              name: "Registrar",
              ref: "/register",
              route: "register",
              roles: ["ADM", "COM"],
              icon: "file-signature",
            },
            {
              name: "Proveedores",
              ref: "/providers",
              route: "providers",
              roles: ["ADM", "COM"],
              icon: "user-check",
            },
            {
              name: "Radicar",
              ref: "/invoice",
              route: "invoice",
              roles: ["ADM", "COM", "PRO"],
              icon: "file-invoice",
            },
            {
              name: "Radicadas",
              ref: "/invoice/list",
              route: "invoices-list",
              roles: ["PRO"],
              icon: "clipboard-list",
            },
            {
              name: "Listado",
              ref: "/list",
              route: "list",
              roles: ["ADM", "COM", "CAR"],
              icon: "list-ul",
            },
          ],
        };
      } else {
        return {
          pages: [
            {
              name: "Dashboard",
              ref: "/home",
              route: "home",
              roles: ["ADM", "COM"],
              icon: "home",
            },
            {
              name: "Companies",
              ref: "/companies",
              route: "companies",
              roles: [ "COM"],
              icon: "building",
            },
            {
              name: "Users",
              ref: "/users",
              route: "users",
              roles: ["ADM", "COM"],
              icon: "users",
            },
            {
              name: "Invite",
              ref: "/invite",
              route: "invite",
              roles: ["ADM", "COM"],
              icon: "paper-plane",
            },
            {
              name: "Sign up",
              ref: "/register",
              route: "register",
              roles: ["ADM", "COM"],
              icon: "file-signature",
            },
            {
              name: "Providers",
              ref: "/providers",
              route: "providers",
              roles: ["ADM", "COM"],
              icon: "user-check",
            },
            {
              name: "Fill ",
              ref: "/invoice",
              route: "invoice",
              roles: ["ADM", "COM", "PRO"],
              icon: "file-invoice",
            },
            {
              name: "Filled",
              ref: "/invoice/list",
              route: "invoices-list",
              roles: ["PRO"],
              icon: "clipboard-list",
            },
            {
              name: "List",
              ref: "/list",
              route: "list",
              roles: ["ADM", "COM", "CAR"],
              icon: "list-ul",
            },
          ],
        };
      }
    },
    pages() {
      return this.labels.pages.filter((page) => {
        return page.roles.find((item) => {
               return item === this.$store.state.userRole;
        });
      });
    },
  },
};
</script>

<style>
/* d-none d-md-flex */
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 80px;
  background: var(--five);
  /* box-shadow: 2px 0px 20px 0 rgba(31, 38, 135, 0.37); */
  /* backdrop-filter: blur(4px); */
  /* -webkit-backdrop-filter: blur(4px); */
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
  /* display: block; */
  transition: 0.3s width ease;
  z-index: 1000;
  padding: 20px;
  overflow-x: hidden;
}
#sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#sidebar ul li:first-child {
  margin: 40px 0 40px;
}
#sidebar:hover ul li:first-child {
  padding: 12px;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 12px;
  margin: 28px 0 28px;
}
#sidebar ul li:first-child svg {
  background: var(--first);
}
#sidebar ul li {
  width: 100%;
  border-radius: 6px;
  transition: all 0.3s ease;
  margin: 5px 0;
}
#sidebar:hover ul li {
  padding-left: 12px;
}
#sidebar ul li.active-page {
  background: var(--second);
}
#sidebar ul li.active-page svg{
  color: var(--first);
}
#sidebar ul li:hover {
  padding-left: 16px;
  background: rgba(255, 255, 255, 0.5);
}
#sidebar ul li a {
  display: flex;
  align-items: center;
  height: 40px;
  color: var(--first) !important;
  text-decoration: none;
  font-size: 1.1rem;
}

#sidebar ul li a span {
  display: none;
  margin-left: 1rem;
}

#sidebar ul li svg {
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 6px;
  font-size: 2rem;
  color: var(--icon);
}
#sidebar:hover {
  width: 250px;
  
}

#sidebar:hover span {
  display: block;
}

/* Small screens */
@media (max-width: 575.98px) {
  #sidebar {
    top: auto;
    bottom: 0;
    min-width: 100%;
    width: 100%;
    height: 3rem;
    overflow: hidden;
  }
  #sidebar ul {
    flex-direction: row;
    justify-content: center;
  }
  #sidebar ul li:first-child {
    display: none;
  }
  #sidebar:hover ul li {
    padding-left: 0px;
  }
  #sidebar ul li:hover {
    padding-left: 0px;
    background: var(--seven);
  }
  #sidebar:hover {
    min-width: 100%;
  }

  #sidebar:hover span {
    display: none;
  }
  #sidebar ul li a {
    justify-content: center;
  }
}
</style>
