<template>
  <div class="dashboard">
    <b-container v-if="!isLoading">
      <b-row class="summary">
        <b-col md="8" class="left-summ">
          <h5>{{labels.summary}}</h5>
          <h2>{{ invoicesChartTotal + " " + labels.invoices}} </h2>
          <div class="chart" v-if="invoicesChartDays.length != 0">
            <div
              :class="[
                maxValuePosition == 4 ? 'active-bar' : '',
                'bar-' + invoicesChartNumbers[4],
              ]"
              v-if="invoicesChartDays.length > 4"
            >
              <span class="day-4">{{ invoicesChartDays[4] }}</span>
            </div>
            <div
              :class="[
                maxValuePosition == 3 ? 'active-bar' : '',
                'bar-' + invoicesChartNumbers[3],
              ]"
              v-if="invoicesChartDays.length > 3"
            >
              <span class="day-3">{{ invoicesChartDays[3] }}</span>
            </div>
            <div
              :class="[
                maxValuePosition == 2 ? 'active-bar' : '',
                'bar-' + invoicesChartNumbers[2],
              ]"
              v-if="invoicesChartDays.length > 2"
            >
              <span class="day-2">{{ invoicesChartDays[2] }}</span>
            </div>
            <div
              :class="[
                maxValuePosition == 1 ? 'active-bar' : '',
                'bar-' + invoicesChartNumbers[1],
              ]"
              v-if="invoicesChartDays.length > 1"
            >
              <span class="day-1">{{ invoicesChartDays[1] }}</span>
            </div>
            <div
              :class="[
                maxValuePosition == 0 ? 'active-bar' : '',
                'bar-' + invoicesChartNumbers[0],
              ]"
              v-if="invoicesChartDays.length > 0"
            >
              <span class="day-0">{{ invoicesChartDays[0] }}</span>
            </div>
          </div>
          <div v-else>
            <h3>{{labels.noInvoices}}</h3>
          </div>
        </b-col>
        <b-col md="4" class="right-summ">
          <div class="big-number">
            <h5>{{labels.total}}</h5>
            <h1>{{ invoicesTotal }}</h1>
            <span class="circle-1"></span>
            <span class="circle-2"></span>
          </div>
        </b-col>
      </b-row>
      <b-row class="others">
        <b-col lg="6" class="pr-lg-3">
          <div class="users">
            <h5>{{labels.users}}</h5>
            <table>
              <tbody>
                <tr v-for="item in users" :key="item.id">
                  <td>{{ item.username }}</td>
                  <td v-if="item.enabled" class="user-active">{{labels.active}}</td>
                  <td v-else class="user-inactive">{{labels.inactive}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="providers">
            <div class="total-profivers">
              <h6>{{labels.providers}}</h6>
              <h2>{{ providersTotal }}</h2>
            </div>
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'globe']" />
            </div>
          </div>
        </b-col>
        <b-col lg="6" class="pl-lg-3">
          <div class="last-invoices">
            <h5>{{labels.lastInvoices}}</h5>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{labels.provider}}</th>
                  <th>{{labels.date}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in lastInvoices" :key="item.id">
                  <td>{{ item.number }}</td>
                  <td>{{ item.provider }}</td>
                  <td>{{ item.createdAt }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Invoice from "@/services/Invoice";
import Provider from "@/services/Provider";
import User from "@/services/User";

export default {
  name: "List",

  data: () => ({
    currentSort: "createdAt",
    currentSortOrder: "asc",
    showDialog: false,
    dialogItem: {},
    inscriptions: [],
    searched: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    success: false,
    error: false,
    users: [],
    invoices: [],
    lastInvoices: [],
    providersTotal: 0,
    invoicesChartTotal: 0,
    invoicesChart: {},
    invoicesChartNumbers: [],
    invoicesChartDays: [],
    invoicesTotal: 0,
    maxValuePosition: 0,
  }),
  computed: {
    labels() {
      if (this.$store.state._language == "es-ES") {
        return {
          summary: "Resumen",
          invoices: "Facturas",
          total: "Total",
          users: "Usuarios",
          active: "Activo",
          inactive: "Inactivo",
          providers: "Total Proveedores",
          lastInvoices: "Últimas Facturas",
          provider: "Proveedor",
          date: "Fecha",
          noInvoices: "Aún no cuentas con facturas"
        };
      } else {
        return {
          summary: "Summary",
          invoices: "Invoices",
          total: "Total",
          users: "Users",
          active: "Active",
          inactive: "Inactive",
          providers: "Providers Total",
          lastInvoices: "Last Invoices",
          provider: "Provider",
          date: "Date",
          noInvoices: "No invoices Found"
        };
      }
    },
    role() {
      // console.log(this.$store.state.userRole)
      return this.$store.state.userRole;
    },
    pages() {
      return this.labels.pages.filter((page) => {
        return page.roles.find((item) => {
          return item === this.$store.state.userRole;
        });
      });
    },
  },
  methods: {},
  mounted() {
    this.isLoading = true;
    this.currentDay = new Date().getDate();
    if (this.$store.state.userRole == "COM") {
      Invoice.getAll()
        .then((fetchedData) => {
          this.invoices = fetchedData.data.invoices;
          this.invoicesTotal = this.invoices.length;
          if (this.invoicesTotal > 6) {
            this.lastInvoices = this.invoices.slice(0, 4);
          } else {
            this.lastInvoices = this.invoices;
          }
          this.invoices.forEach((element) => {
            if (element.createdAt.slice(0, 5) in this.invoicesChart) {
              this.invoicesChart[element.createdAt.slice(0, 5)] += 1;
            } else {
              this.invoicesChart[element.createdAt.slice(0, 5)] = 1;
            }
          });
          this.invoicesChartDays = Object.keys(this.invoicesChart);
          this.invoicesChartNumbers = Object.values(this.invoicesChart);
          if (this.invoicesChartDays > 5) {
            this.invoicesChartDays = this.invoicesChartDays.slice(0, 5);
            this.invoicesChartNumbers = this.invoicesChartNumbers.slice(0, 5);
          }
          this.maxValuePosition = this.invoicesChartNumbers.indexOf(
            Math.max(...this.invoicesChartNumbers)
          );
          this.invoicesChartTotal = this.invoicesChartNumbers.reduce(
            (a, b) => a + b,
            0
          );
        })
        .catch(() => {
          this.error = true;
        });
      Provider.getAll()
        .then((fetchedData) => {
          this.providersTotal = fetchedData.data.inscriptions.length;
        })
        .catch(() => {
          this.error = true;
        });

      User.getAllUsers()
        .then((fetchedData) => {
          this.users = fetchedData.data.users;
          if (this.users.length > 4) {
            this.users = this.users.slice(0, 4);
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      Invoice.getAllByCompany(this.$store.state.companyId)
        .then((fetchedData) => {
          this.invoices = fetchedData.data.invoices;
          this.invoicesTotal = this.invoices.length;
          if (this.invoicesTotal > 6) {
            this.lastInvoices = this.invoices.slice(0, 4);
          } else {
            this.lastInvoices = this.invoices;
          }
          this.invoicesChartDays = Object.keys(this.invoicesChart);
          this.invoicesChartNumbers = Object.values(this.invoicesChart);
          if (this.invoicesChartDays > 5) {
            this.invoicesChartDays = this.invoicesChartDays.slice(0, 5);
            this.invoicesChartNumbers = this.invoicesChartNumbers.slice(0, 5);
          }
          this.maxValuePosition = this.invoicesChartNumbers.indexOf(
            Math.max(...this.invoicesChartNumbers)
          );
          this.invoicesChartTotal = this.invoicesChartNumbers.reduce(
            (a, b) => a + b,
            0
          );
        })
        .catch(() => {
          this.error = true;
        });
      Provider.getAllByCompany(this.$store.state.companyId)
        .then((fetchedData) => {
          this.providersTotal = fetchedData.data.inscriptions.length;
        })
        .catch(() => {
          this.error = true;
        });

      User.getAllByCompany(this.$store.state.companyId)
        .then((fetchedData) => {
          this.users = fetchedData.data.users;
          if (this.users.length > 4) {
            this.users = this.users.slice(0, 4);
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
};
</script>

<style src="../main.css"></style>
