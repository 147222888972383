import Api from '@/services/Api'

export default {
    createCompany (form) {
      return Api().post('companies/create', form)
    },
    getAllCompanies(){
        return Api().get('companies')
    },
    getOneCompanie(id){
        return Api().get('companies/'+id)
    },
    deleteCompany (id) {
      return Api().put('companies/delete/'+ id)
    },
  }
  
