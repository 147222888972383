<template>
  <div>
    <b-row>
      <b-container>
        <h1 class="text-center py-2">
          {{ labels.title }}
        </h1>
      </b-container>
    </b-row>
    <b-row v-if="!isLoading">
      <b-container v-if="!isEmpty" class="pb-5">
        <h4 class="text-center">
          {{ labels.info }}
        </h4>
        <b-col md="12" class="mx-auto">
          <md-table
            v-model="searched"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            md-card md-fixed-header
          >
            <md-table-toolbar>
              <md-field md-clearable class="md-toolbar-section-start">
                <md-input
                  :placeholder="labels.search"
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              @click="showInfo(item.id)"
            >
              <md-table-cell
                :md-label="labels.signupDate"
                md-sort-by="createdAt"
                >{{ item.createdAt }}</md-table-cell
              >
              <md-table-cell :md-label="labels.name" md-sort-by="name"
                >{{ item.social + item.name }}
              </md-table-cell>
              <md-table-cell
                :md-label="labels.status"
                md-sort-by="paymentPolicy"
              >
                <span v-if="item.paymentPolicy > 0" class="policy-active"
                  >Asignado</span
                >
                <span v-else class="policy-pending">Pendiente</span>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </b-col>
        <b-col
          md="6"
          class="mx-auto empty-table pt-4"
          v-if="searched.length == 0 && inscriptions.length > 0 && !isLoading"
        >
          <h3>{{ labels.notFound }}</h3>
          <p>
            {{ labels.notFoundMessage }}:
            <span>{{ search }}</span>
          </p>
        </b-col>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div class="success-svg mb-4" :class="{ active: isEmpty }">
            <img src="../../assets/imgs/file-2.svg" alt="warning" />
          </div>
        </b-col>
        <b-col md="12" class="justify-content-around">
          <span id="message" v-if="isEmpty">{{ labels.empty }}</span>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>{{labels.dialog.title}}</md-dialog-title>
      <md-dialog-content>
        <md-tabs md-dynamic-height>
          <md-tab :md-label="labels.dialog.basic.title">
            <b-row>
              <b-col>
                <h5>{{labels.dialog.basic.services}}:</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.services }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.basic.type}}:</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.type }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.basic.companyName}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.social + dialogItem.name }}</p>
              </b-col>
            </b-row>
            <div v-if="dialogItem.type != 'Natural'">
              <b-row>
                <b-col>
                  <h5>{{labels.dialog.basic.entityType}}</h5>
                </b-col>
                <b-col>
                  <p v-if="dialogItem.entityType == 'public'">{{labels.dialog.basic.public}}</p>
                  <p v-else-if="dialogItem.entityType == 'private'">{{labels.dialog.basic.private}}</p>
                  <p v-else>{{labels.dialog.basic.mixed}}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h5>{{labels.dialog.basic.nit}}</h5>
                </b-col>
                <b-col>
                  <p>{{ dialogItem.nit }}</p>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h5>{{labels.dialog.basic.legalName}}</h5>
                </b-col>
                <b-col>
                  <p>{{ dialogItem.legal }}</p>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.basic.idType}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.idType }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.basic.idNumber}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.idNumber }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.basic.address}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.address }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.basic.phoneNumber}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.phone }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.basic.ciiu}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.ciiuCode }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.basic.email}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.email }}</p>
              </b-col>
            </b-row>
          </md-tab>
          <md-tab :md-label="labels.dialog.financial.title">
            <b-row>
              <b-col>
                <h5>{{labels.dialog.financial.income}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.income }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.financial.outcome}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.outcome }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.financial.active}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.active }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.financial.pasive}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.pasive }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.financial.otherIncome}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.otherIncome }}</p>
              </b-col>
            </b-row>
          </md-tab>
          <md-tab
            :md-label="labels.dialog.partners.title"
            v-if="dialogItem.type != 'Natural'"
          >
            <md-table v-model="dialogItem.shareholders">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell :md-label="labels.dialog.partners.name">{{ item.name }}</md-table-cell>
                <md-table-cell :md-label="labels.dialog.partners.identification">{{
                  item.type
                }}</md-table-cell>
                <md-table-cell :md-label="labels.dialog.partners.number">{{
                  item.number
                }}</md-table-cell>
                <md-table-cell :md-label="labels.dialog.partners.percentage">{{
                  item.percentage
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-tab>
          <md-tab :md-label="labels.dialog.tributary.title">
            <b-row>
              <b-col>
                <h5>{{labels.dialog.tributary.type}}</h5>
              </b-col>
              <b-col>
                <p>{{ dialogItem.tributaryType }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>{{labels.dialog.tributary.isSelfRetainer}}</h5>
              </b-col>
              <b-col>
                <p v-if="dialogItem.selfRetainer">{{labels.dialog.tributary.isRetainer}}</p>
                <p v-else>No</p>
              </b-col>
            </b-row>
          </md-tab>
          <md-tab :md-label="labels.dialog.policy.title">
            <md-field>
              <md-select
                name="payTime"
                placeholder="Días"
                v-model="dialogItem.paymentPolicy"
              >
                <md-option value="15">15</md-option>
                <md-option value="30">30</md-option>
                <md-option value="60">60</md-option>
                <md-option value="90">90</md-option>
                <md-option value="120">120</md-option>
              </md-select>
            </md-field>
          </md-tab>
        </md-tabs>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="updatePolicy(dialogItem.id)"
          >{{labels.dialog.send}}</md-button
        >
        <md-button class="md-accent" @click="showDialog = false"
          >{{labels.dialog.close}}</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Register from "@/services/Register";
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.social + item.name).includes(toLower(term))
    );
  }
  return items;
};
export default {
  name: "List",

  data: () => ({
    currentSort: "createdAt",
    currentSortOrder: "asc",
    showDialog: false,
    dialogItem: {},
    inscriptions: [],
    searched: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    success: false,
    error: false,
    filename: "Providers.xls",
  }),
  computed: {
    labels() {
      if (this.$store.state._language == "es-ES") {
        return {
          title: "Proveedores",
          subtitle: "Instrucciones",
          instructive: [
            "Bienvenidos a la plataforma para la visualización de proveedores, lo invitamos a revisar la información de cada uno.",
          ],
          info: "Información General",
          search: "Buscar por Nombre",
          signupDate: "Fecha Inscripción",
          name: "Nombre / Razón Social",
          status: "Estado",
          empty: "Aún no cuentas con Proveedores",
          download: "Descargar",
          notFound: "No Hay Proveedores",
          notFoundMessage: "No hay proveedores con ese nombre",
          dialog: {
            title: "Información de Registro",
            basic: {
              title: "Basica",
              services: "Servicios",
              type: "Tipo de persona",
              companyName: "Razón Social / Nombre", 
              idType: "Tipo de Identificación",
              idNumber: "Número de identificación",
              address: "Dirección",
              phoneNumber: "Teléfono",
              ciiu: "Código CIIU",
              email: "Correo Electrónico",
              entityType: "Tipo de Entidad",
              public: "Publica",
              private: "Privada",
              mixed: "Mixta",
              nit: "NIT",
              legalName: "Representante Legal"
            },
            financial: {
              title: "Financiera",
              income: "Ingresos",
              outcome: "Egresos",
              active: "Activos",
              pasive:"Pasivos",
              otherIncome: "Otros Ingresos"
            },
            partners:{
              title: "Socios / Accionistas",
              name: "Nombre",
              identification: "Identificación",
              number: "Número",
              percentage: "% Participación"
            },
            tributary: {
              title: "Tributaria",
              type: "Tipo",
              isSelfRetainer: "Autoretenedor",
              isRetainer: "Si",
            },
            policy:{
              title: "Politica de pago"
            },
            send: "Aceptar y enviar correo",
            close: "Cerrar"
          }
        };
      } else {
        return {
          title: "Proveedores",
          subtitle: "Instrucciones",
          instructive: [
            "Bienvenidos a la plataforma para la visualización de proveedores, lo invitamos a revisar la información de cada uno.",
          ],
          info: "Información de Proveedores",
          search: "Buscar por Nombre",
          signupDate: "Fecha Inscripción",
          name: "Nombre / Razón Social",
          status: "Estado",
          empty: "Aún no cuentas con Proveedores",
          download: "Download",
          notFound: "Not Found",
          notFoundMessage: "No providers found with that name",
          dialog: {
            title: "Sign up Information",
            basic: {
              title: "Basic",
              services: "Services",
              type: "Company/Person Type",
              companyName: "Company name / Name", 
              idType: "ID type",
              idNumber: "ID number",
              address: "Address",
              phoneNumber: "Phone Number",
              ciiu: "CIIU code",
              email: "Email",
              entityType: "Entity Type",
              public: "Public",
              private: "Private",
              mixed: "Mixed",
              nit: "NIT",
              legalName: "Legal Representative"
            },
            financial: {
              title: "Financial",
              income: "Income",
              outcome: "Outcome",
              active: "Assets",
              pasive:"Liabilities",
              otherIncome: "Other Income"
            },
            partners:{
              title: "Partners / Shareholders",
              name: "Name",
              identification: "Identification",
              number: "Number",
              percentage: "Participation %"
            },
            tributary: {
              title: "Tributary",
              type: "Type",
              isSelfRetainer: "Is Self Retainer",
              isRetainer: "Yes",
            },
            policy:{
              title: "Payment Policy"
            },
            send: "Accept and send Email",
            close: "Close"
          }
        };
      }
    },
  },
  methods: {
    showInfo(id) {
      const currentIdx = this.inscriptions.findIndex((obj) => obj.id == id);
      this.dialogItem = this.inscriptions[currentIdx];
      this.showDialog = true;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    searchOnTable() {
      this.searched = searchByName(this.inscriptions, this.search);
    },
    updatePolicy(id) {
      const currentIdx = this.inscriptions.findIndex((obj) => obj.id == id);
      const provider = this.inscriptions[currentIdx];
      console.log(provider.paymentPolicy);
      const form = {
        email: provider.email,
        language: provider.language,
        paymentPolicy: provider.paymentPolicy,
      };
      Register.updatePolicy(form)
        .then(() => {})
        .catch(() => {});
      this.showDialog = false;
    },
  },
  mounted() {
    this.isLoading = true;
    Register.get()
      .then((result) => {
        this.inscriptions = result.data.inscriptions;
        this.searched = this.inscriptions;
        if (this.inscriptions.length == 0) {
          this.isEmpty = true;
        }
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      })
      .finally(() => {
        console.log(this.$store.state)
        if (this.$store.state.userRole == "ADM") {
           this.inscriptions = this.inscriptions.filter( inscription => inscription.companyId == this.$store.state.companyId );
           this.searched = this.inscriptions
        }
        this.isLoading = false;
      });
  },
};
</script>

<style src="../main.css"></style>
