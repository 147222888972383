var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard"},[(!_vm.isLoading)?_c('b-container',[_c('b-row',{staticClass:"summary"},[_c('b-col',{staticClass:"left-summ",attrs:{"md":"8"}},[_c('h5',[_vm._v(_vm._s(_vm.labels.summary))]),_c('h2',[_vm._v(_vm._s(_vm.invoicesChartTotal + " " + _vm.labels.invoices)+" ")]),(_vm.invoicesChartDays.length != 0)?_c('div',{staticClass:"chart"},[(_vm.invoicesChartDays.length > 4)?_c('div',{class:[
              _vm.maxValuePosition == 4 ? 'active-bar' : '',
              'bar-' + _vm.invoicesChartNumbers[4],
            ]},[_c('span',{staticClass:"day-4"},[_vm._v(_vm._s(_vm.invoicesChartDays[4]))])]):_vm._e(),(_vm.invoicesChartDays.length > 3)?_c('div',{class:[
              _vm.maxValuePosition == 3 ? 'active-bar' : '',
              'bar-' + _vm.invoicesChartNumbers[3],
            ]},[_c('span',{staticClass:"day-3"},[_vm._v(_vm._s(_vm.invoicesChartDays[3]))])]):_vm._e(),(_vm.invoicesChartDays.length > 2)?_c('div',{class:[
              _vm.maxValuePosition == 2 ? 'active-bar' : '',
              'bar-' + _vm.invoicesChartNumbers[2],
            ]},[_c('span',{staticClass:"day-2"},[_vm._v(_vm._s(_vm.invoicesChartDays[2]))])]):_vm._e(),(_vm.invoicesChartDays.length > 1)?_c('div',{class:[
              _vm.maxValuePosition == 1 ? 'active-bar' : '',
              'bar-' + _vm.invoicesChartNumbers[1],
            ]},[_c('span',{staticClass:"day-1"},[_vm._v(_vm._s(_vm.invoicesChartDays[1]))])]):_vm._e(),(_vm.invoicesChartDays.length > 0)?_c('div',{class:[
              _vm.maxValuePosition == 0 ? 'active-bar' : '',
              'bar-' + _vm.invoicesChartNumbers[0],
            ]},[_c('span',{staticClass:"day-0"},[_vm._v(_vm._s(_vm.invoicesChartDays[0]))])]):_vm._e()]):_c('div',[_c('h3',[_vm._v(_vm._s(_vm.labels.noInvoices))])])]),_c('b-col',{staticClass:"right-summ",attrs:{"md":"4"}},[_c('div',{staticClass:"big-number"},[_c('h5',[_vm._v(_vm._s(_vm.labels.total))]),_c('h1',[_vm._v(_vm._s(_vm.invoicesTotal))]),_c('span',{staticClass:"circle-1"}),_c('span',{staticClass:"circle-2"})])])],1),_c('b-row',{staticClass:"others"},[_c('b-col',{staticClass:"pr-lg-3",attrs:{"lg":"6"}},[_c('div',{staticClass:"users"},[_c('h5',[_vm._v(_vm._s(_vm.labels.users))]),_c('table',[_c('tbody',_vm._l((_vm.users),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.username))]),(item.enabled)?_c('td',{staticClass:"user-active"},[_vm._v(_vm._s(_vm.labels.active))]):_c('td',{staticClass:"user-inactive"},[_vm._v(_vm._s(_vm.labels.inactive))])])}),0)])]),_c('div',{staticClass:"providers"},[_c('div',{staticClass:"total-profivers"},[_c('h6',[_vm._v(_vm._s(_vm.labels.providers))]),_c('h2',[_vm._v(_vm._s(_vm.providersTotal))])]),_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'globe']}})],1)])]),_c('b-col',{staticClass:"pl-lg-3",attrs:{"lg":"6"}},[_c('div',{staticClass:"last-invoices"},[_c('h5',[_vm._v(_vm._s(_vm.labels.lastInvoices))]),_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.labels.provider))]),_c('th',[_vm._v(_vm._s(_vm.labels.date))])])]),_c('tbody',_vm._l((_vm.lastInvoices),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.number))]),_c('td',[_vm._v(_vm._s(item.provider))]),_c('td',[_vm._v(_vm._s(item.createdAt))])])}),0)])])])],1)],1):_c('b-row',[_c('b-col',{staticClass:"p-4 mx-audo",attrs:{"md":"12","lg":"12"}},[_c('div',{staticClass:"text-center"},[_c('b-spinner')],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }