import Api from '@/services/Api'

export default {
    getAllUsers(){
        return Api().get('users')
    },
    getAllByCompany(id){
      return Api().get('users/company/'+id)
  },
    deleteUser (id) {
      return Api().put('users/delete/'+ id)
    },
    createUser (form){
      return Api().post('admin/users', form)
    }
  }
  