import Api from '@/services/Api'

export default {
  send (form) {
    return Api().post('invoice', form)
  },
  getAll(){
    return Api().get('invoice')
  },
  getAllByCompany(id){
    return Api().get('invoice/company/'+ id)
  },
  getAllByProvider(id){
    return Api().get('invoice/provider/' + id)
  },
  getSingle(number, provider){
    return Api().get('invoice/single/'+provider+ '/'+number)
  },
  updatePaydate(id, date){
    return Api().put('invoice/'+id+'/paydate',{date: date})
  },
  updateComment(id, comment){
    return Api().put('invoice/'+id+'/comment',{comment: comment})
  },
  download(id){
    console.log(id)
    return Api().get('invoice/download/'+id, {
      responseType: 'blob'
  })
  },
  changeStatus(invoiceId, status) {
    return Api().put('invoice/status/'+invoiceId, {status: status})
  }
}
