import axios from 'axios'
import store from '@/store'

export default () => {

  if	(process.env.NODE_ENV == "development") {
         return axios.create({
           baseURL: "http://localhost:7080/api/",
           headers: {
             Authorization: `Bearer ${store.state.token}`
           }
         })
  } else {
         return axios.create({
           baseURL: "https://invoices.complementservices.com/api/",
           headers: {
             Authorization: `Bearer ${store.state.token}`
           }
         })
  }
}
