import Api from '@/services/Api'

export default {
  get () {
    return Api().get('inscription')
  },
  updatePolicy(form){
    return Api().put('send-registration', form)
  },
  send (form) {
    return Api().post('inscription', form)
  },
  invite(form){
    return Api().post('send-invite', form)
  },
  validate(token){
    return Api().get('validate-registration?t='+token)
  },
}
