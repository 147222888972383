import Api from '@/services/Api'

export default {
    getAll(){
        return Api().get('inscription')
    },
    getAllByCompany(id){
        return Api().get('inscription/'  + id)
    },
  }
  