import Vue from "vue";
import Vuex from 'vuex'
import App from "./App.vue";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue } from "bootstrap-vue";
import VueMaterial from "vue-material";
import VueSwal from 'vue-swal'
import loader from "vue-ui-preloader";
import store from "@/store"
import 'es6-promise/auto'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";


library.add(fas);
library.add(fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.use(VueMaterial);
Vue.use(loader);
Vue.use(Vuex);
Vue.use(VueSwal)
Vue.config.productionTip = false;
Vue.material.locale.dateFormat = "dd/MM/yyyy";
// localStorage._language = navigator.language
// export const languageBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
