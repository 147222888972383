<template>
  <div>
    <div class="login-bg">
      <b-row>
        <b-col md="6" lg="4" data-aos="fade-right" class="left-side">
          <b-form @submit.prevent="onSubmit" id="form-login">
            <div class="icon-container">
              <img src="../assets/imgs/logo-Symbol.png" alt="logo" srcset="" />
              <span>Complement Services</span>
            </div>
            <div class="content">
              <h3>{{ labels.greeting }}</h3>
              <p>{{ labels.info }}</p>
              <p v-if="error" class="invalid-login">{{ labels.err }}</p>
            </div>
            <b-form-group :class="{ formLoading: isLoading }">
              <md-field>
                <label>{{ labels.email }}</label>
                <span><font-awesome-icon :icon="['fas', 'envelope']"/></span>
                <md-input
                  type="text"
                  name="email"
                  v-model="form.email"
                  :placeholder="labels.email"
                  required
                />
              </md-field>
              <md-field>
                <label>{{ labels.password }}</label>
                <span><font-awesome-icon :icon="['fas', 'lock']"/></span>
                <md-input
                  type="password"
                  name="password"
                  v-model="form.password"
                  :placeholder="labels.password"
                  required
                />
              </md-field>
              <b-button type="submit" id="login-btn">{{
                labels.login
              }}</b-button>     

              <div class="more">
                <a href="#" @click.prevent="changePassword()" class="forgot">{{
                labels.forgot
              }}</a>
              <a href="#" @click.prevent="changeLanguage()" class="lang">{{
                labels.lang
              }}</a>
              </div>         
            </b-form-group>
            
            <div class="loadingSpinner" :class="{ active: isLoading }">
              <b-spinner></b-spinner>
            </div>
          </b-form>
        </b-col>
        <b-col md="6" lg="8" data-aos="fade-left" class="right-side p-0">
          <img src="../assets/imgs/login-right-side2.png" alt="" srcset="">
          <h2>{{labels.sentence}}</h2>
        </b-col>
      </b-row>
    </div>
    <!-- <loader id="pre-loader" object="#21dec7" color1="#884af4" color2="#f28c44" size="5" speed="1.5" bg="#000000" objectbg="#999793" opacity="70" name="circular"></loader> -->
  </div>
</template>

<script>
import Auth from "@/services/Authentication";

export default {
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    
    isLoading: false,
    error: false,
    isFetching: true,
  }),
  methods: {
    onSubmit() {
      this.error = false;
      this.isLoading = true;
      let nextRoute = "";
      Auth.login(this.form)
        .then((user) => {
          user.data.isAuthenticated = true;
          this.$store.commit("setData", user.data);
          switch(user.data.role){
          case 'ADM':
            nextRoute = {name: 'home'};
            break;
          case 'COM':
            nextRoute = {name: 'home'};
            break;
          case 'CAR':
            nextRoute = {name: 'list'};
            break;
          case 'PRO':
            nextRoute = {name: 'invoice'};
            break;
        }
          
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
          this.$router.push(nextRoute);
        });
    },
    changePassword() {
      this.isLoading = true;
      Auth.nuevoPassword(this.form.email)
      .then(() => {
             this.$swal("Solicitud Cambio de clave Exitosa!", "Un mensaje fue enviado a su correo","success")
      })
      this.isLoading = false;
    },
    changeLanguage() {
      this.isLoading = true;
      if (this.$store.state._language == "en-US") {
        this.$store.commit("setLang", "es-ES");
      } else {
        this.$store.commit("setLang", "en-US");
      }
      this.isLoading = false;
    },
  },
  beforeMount(){
    if(this.$store.state.isAuthenticated){
      let nextRoute = "";
    switch(this.$store.state.userRole){
          case 'ADM':
            nextRoute = {name: 'home'};
            break;
          case 'COM':
            nextRoute = {name: 'home'};
            break;
          case 'CAR':
            nextRoute = {name: 'list'};
            break;
          case 'PRO':
            nextRoute = {name: 'invoice'};
            break;
        }
          this.$router.push(nextRoute);

    }
  },
  computed: {
    labels(){
    if (this.$store.state._language == "es-ES") {
      return {
        greeting: "Iniciar Sesión",
        info: "Ingresa los datos que utilizaste para el registro",
        email: "Correo Electrónico",
        password: "Contraseña",
        login: "Ingresar",
        lang: "English Version",
        err: "Credenciales Incorrectas",
        forgot: "Olvidé mi contraseña",
        sentence: "Comienza a mejorar los procesos financieros de tu empresa"
      };
    } else {
      return  {
        greeting: "Login",
        info: "Fill the fields with the registration information you provide",
        email: "E-mail Address",
        password: "Password",
        login: "Login",
        lang: "Versión en Español",
        err: "Invalid Credentials",
        forgot: "Olvidé mi contraseña",
        sentence: "Start to improve your business financial operations"
      };
    }
    }
  },
};
</script>

<style src="./main.css"></style>
