<template>
  <footer class="foot">
    <span>
      © 2020 Copyright:
      Complement Services
    </span>
  </footer>
  
</template>

<script>
export default {
};
</script>

<style >


</style>