<template>
  <div>
    <b-row>
      <b-container>
        <h1 class="text-center py-2">
          {{ labels.title }}
        </h1>
      </b-container>
    </b-row>
    <b-row v-if="!isLoading">
      <b-container v-if="!isEmpty" class="pb-5">
        <b-col md="12" class="mx-auto">
          <md-table
            v-model="searched"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            md-card md-fixed-header
          >
            <md-table-toolbar>
              <md-field md-clearable class="md-toolbar-section-start">
                <md-input
                  :placeholder="labels.search"
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              @click="showInfo(item.id)"
            >
              <md-table-cell
                :md-label="labels.locatedNumber"
                md-sort-by="located"
                >{{ item.located }}</md-table-cell
              >
              <md-table-cell
                :md-label="labels.locatedDate"
                md-sort-by="createdAt"
                >{{ item.createdAt }}</md-table-cell
              >
              <md-table-cell :md-label="labels.invoiceDate" md-sort-by="date">{{
                item.date
              }}</md-table-cell>
              <md-table-cell
                :md-label="labels.provider"
                md-sort-by="provider"
                >{{ item.provider }}</md-table-cell
              >
              <md-table-cell
                :md-label="labels.invoiceNumber"
                md-sort-by="number"
                >{{ item.number }}</md-table-cell
              >
              <md-table-cell :md-label="labels.payDate" >
              {{item.payDate}}
            </md-table-cell>
            <md-table-cell :md-label="labels.paymentValue" >
              {{item.total}}
            </md-table-cell>

              <md-table-cell :md-label="labels.approved">
                <span v-if="item.status == 'PND'" class="status pending">{{labels.status[0]}}</span>
                <span v-if="item.status == 'DEC'" class="status declined">{{labels.status[1]}}</span>
                <span v-if="item.status == 'APP'" class="status approved">{{labels.status[2]}}</span>
              </md-table-cell>
              <md-table-cell :md-label="labels.comment">
                {{ item.comment }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </b-col>
        <b-col
          md="6"
          class="mx-auto empty-table pt-4"
          v-if="searched.length == 0 && invoices.length > 0 && !isLoading"
        >
          <h3>{{labels.notFound}}</h3>
          <p>
            {{labels.notFoundMessage}}
            <span>{{ search }}</span>
          </p>
        </b-col>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div class="success-svg mb-4" :class="{ active: isEmpty }">
            <img src="../../assets/imgs/file-2.svg" alt="warning" />
          </div>
        </b-col>
        <b-col md="12" class="justify-content-around">
          <span id="message" v-if="isEmpty">{{ labels.empty }}</span>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>{{labels.invoiceInformation}}</md-dialog-title>
      <md-dialog-content>
            <b-row><b-col><h5>{{ labels.currency }}</h5></b-col>
            <b-col><h5>{{ dialogItem.currency }}</h5></b-col></b-row><hr>
            <b-row><b-col><h5>{{ labels.beforeTax }}</h5></b-col>
            <b-col><h5>{{ dialogItem.beforeTax }}</h5></b-col></b-row><hr>
            <b-row><b-col><h5>{{ labels.iva }}</h5></b-col>
            <b-col><h5>{{ dialogItem.ivaTax }}</h5></b-col></b-row><hr>
            <b-row><b-col><h5>{{ labels.impo }}</h5></b-col>
            <b-col><h5>{{ dialogItem.impoconsumo }}</h5></b-col></b-row><hr>
            <b-row><b-col><h5>{{ labels.otherTax }}</h5></b-col>
            <b-col><h5>{{ dialogItem.otherTax }}</h5></b-col></b-row><hr>
            <b-row><b-col><h5>{{ labels.reteFuente }}</h5></b-col>
            <b-col><h5>{{ dialogItem.reteFuente }}</h5></b-col></b-row><hr>
            <b-row><b-col><h5>{{ labels.reteICA }}</h5></b-col>
            <b-col><h5>{{ dialogItem.reteICA }}</h5></b-col></b-row><hr>
            <b-row><b-col><h5>{{ labels.reteIVA }}</h5></b-col>
            <b-col><h5>{{ dialogItem.reteIVA }}</h5></b-col></b-row><hr>
            <b-row><b-col><h5>{{ labels.paymentValue }}</h5></b-col>
            <b-col><h5>{{ dialogItem.total }}</h5></b-col></b-row><hr>
            <b-row><b-col><h5>{{ labels.document }}</h5></b-col>
            <b-col><a href="#" class="download" @click="download(dialogItem.id)">
              <font-awesome-icon :icon="['fas', 'file-download']" /></a></b-col></b-row><hr>
          
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-accent" @click="showDialog = false"
          >{{labels.close}}</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Invoice from "@/services/Invoice";
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByLocated = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.located).includes(toLower(term))
    );
  }

  return items;
};
export default {
  name: "List",

  data: () => ({
    currentSort: "createdAt",
    currentSortOrder: "asc",
    invoices: [],
    searched: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    success: false,
    error: false,
    filename: "List.xls",
    showDialog: false,
    dialogItem: {},
  }),
  computed: {
    labels() {
      if (this.$store.state._language == "es-ES") {
        return {
          title: "Facturas",
          subtitle: "Instrucciones",
          instructive: [
            "Bienvenidos a la plataforma para la visualización de facturas, lo invitamos a revisar la información de cada factura.",
          ],
          info: "Información de Facturas",
          search: "Buscar por Número de Radicado",
          locatedNumber: "# Radicado",
          locatedDate: "Fecha Radicado",
          invoiceDate: "Fecha Factura",
          provider: "Proveedor",
          invoiceNumber: "# Factura",
          currency: "Moneda",
          beforeTax: "Valor antes de impuestos",
          iva: "IVA Facturado",
          impo: "Impoconsumo",
          otherTax: "Otros Impuestos",
          reteFuente: "RETEFUENTE",
          reteICA: "RETEICA",
          reteIVA: "RETEIVA",
          paymentValue: "Valor a Pagar",
          document: "Soporte",
          approved: "Estado",
          comment: "Comentarios",
          payDate: "Fecha de Pago",
          empty: "Aún no cuentas con facturas",
          download: "Descargar",
          status: ["Pendiente","Rechazado","Aprobado"],
          invoiceInformation: "Información de la factura",
          notFound: "No se encontraron facturas",
                    notFoundMessage: "No hay facturas con ese número",
                    close: "Cerrar"
        };
      } else {
        return {
          title: "Invoice Visualization Platform",
          subtitle: "Instructions",
          instructive: [
            "Welcome to the platform for the visualization of invoices, we invite you to review the information of each invoice.",
          ],
          info: "Invoices Information",
          search: "Search by Filing Number",
          locatedNumber: "Filing #",
          locatedDate: "Filing Date",
          invoiceDate: "Invoice Date",
          provider: "Provider",
          invoiceNumber: "Invoice #",
          currency: "Currency",
          beforeTax: "Value before taxes",
          iva: "Invoiced VAT",
          impo: "Impoconsumo",
          otherTax: "Other taxes",
          reteFuente: "RETEFUENTE",
          reteICA: "RETEICA",
          reteIVA: "RETEIVA",
          paymentValue: "Value to be paid",
          document: "Document",
          approved: "Status",
          comment: "Comments",
          payDate: "Payment Date",
          empty: "You don't have invoices yet",
          download: "Download",
          status: ["Pending","Declined","Approved"],
          invoiceInformation: "Invoice Information",
          notFound: "No invoices found",
                    notFoundMessage: "There isn't invoices with that number",
                    close: "Close"
        };
      }
    },
  },
  methods: {
    showInfo(id) {
      const currentIdx = this.invoices.findIndex((obj) => obj.id == id);
      this.dialogItem = this.invoices[currentIdx];
      this.showDialog = true;
    },
    download(id) {
      Invoice.download(id).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id + ".pdf");
        document.body.appendChild(link);
        link.click();
      });
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }

        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    searchOnTable() {
      this.searched = searchByLocated(this.invoices, this.search);
    },
  },
  mounted() {
    this.isLoading = true;
    Invoice.getAllByProvider(this.$store.state.providerId)
      .then((result) => {
        // console.log(result)
        this.invoices = result.data.invoices;
        this.searched = this.invoices;
        if (this.invoices.length == 0) {
          this.isEmpty = true;
        }
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style src="../main.css"></style>
