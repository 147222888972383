<template>
  <div>
    <b-navbar
      class="d-none d-md-flex"
      :class="{shadow: scroll}"
      id="navbarText"
    >
      <!-- <b-container-fluid> -->
      <b-navbar-brand href="/home">
        <img
          src="../../assets/imgs/logo-Symbol.png"
          class="header-logo"
          alt="logo"
        />
      </b-navbar-brand>

      <b-navbar-nav justity-content-center class=" ml-auto">
           <b-nav-item v-if="isAuthenticated">
              <span><h1><i> {{ nombreEmpresa }} </i></h1></span>
           </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class=" ml-auto">
        <b-nav-item @click.prevent="toggleLanguage()">
          <img
            src="../../assets/imgs/es.png"
            alt="es"
            v-if="language == 'es-ES'"
          />
          <img src="../../assets/imgs/en.png" alt="en"  v-else />
        </b-nav-item>
      </b-navbar-nav>
      <span v-if="isAuthenticated">
             <span><h4><i>{{ nombreUsuario }} </i></h4></span>
      </span>
      <b-nav-item @click="logout()" class="login-btn">
        <span v-if="language == 'es-ES'">Salir</span>
        <span v-else>Logout</span>
      </b-nav-item>
      <!-- </b-collapse> -->
      <!-- </b-container-fluid> -->
    </b-navbar>
  </div>
</template>

<script>

import Company from "@/services/Company";
export default {
  name: "Header",
  data: () => ({
      menu: "",
      home: "/#home",
      scroll: false,
      companies: [],
      nombreUsuario: "",
      nombreEmpresa: "",
  }),
  beforeMount() {
    window.addEventListener("scroll", this.scrollFunction);
    const now = new Date();
    const expiresIn = (new Date(this.$store.state.expiration)).getTime() - now.getTime();
    this.nombreUsuario = this.$store.state.userName
    switch (this.$store.state.userRole) {
    case "ADM": this.nombreUsuario=
                this.$store.state.userName+"(ADMINISTRADOR)"
                break
    case "CAR": this.nombreUsuario=
                this.$store.state.userName+"(CARTERA)"
                break
    case "PRO": this.nombreUsuario=
                this.$store.state.userName+"(PROVEEDOR)"
                break
    }

    setTimeout(() => {
      if	(this.$route.name != "registration" && this.$route.name != "set-account") {
                 console.log("HEADER-LOGOUT",this.$route.name)
                 this.logout()
      }
    }, expiresIn);
  },
  methods: {
    scrollFunction(e) {
      this.scroll = window.pageYOffset > 300
      e.preventDefault()
    },
    logout: function() {
      const obj = {
        token: "",
        username: "",
        role: "",
        expiresIn: "",
        isAuthenticated: false,
      };
      this.$store.commit("setData", obj);
      this.$router.push("/");
    },
    toggleLanguage() {
      if (this.$store.state._language == "es-ES") {
//        this.$store.commit("setLang", "en-US");
        this.$store.commit("setLang", "es-ES");
      } else {
        this.$store.commit("setLang", "es-ES");
      }
    },
  },
  computed: {
    role() {
      // console.log(this.$store.state.userRole)
      return this.$store.state.userRole;
    },
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    language() {
      // console.log(this.$store.state._language)
      return this.$store.state._language;
    },
  },
  mounted() {
    Company.getAllCompanies()
      .then((result) => {
        this.companies = result.data.companies;
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        if	(this.$store.state.userRole == "COM") {
                this.nombreEmpresa="SUPERUSUARIO"
        } else {
               const currentIdxC = this.companies.findIndex(
                     (obj) => obj.id == this.$store.state.companyId);
               this.nombreEmpresa=this.companies[currentIdxC].name
        }
        this.isLoading = false;
      });
  },
};
</script>

<style>
/* d-none d-md-flex */
.navbar {
  z-index: 2;
  height: 65px;
  padding: 1.5rem 3rem !important;
}

.navbar-brand img {
  max-height: 35px;
}
.nav-link img {
  height: 35px !important;
  border-radius: 50%;
}
/* .navbar-brand .header-logo{
  position: absolute;
  top: 0;
  height: 80%;
} */
.dropdown-item.active,
.dropdown-item:active {
  /* color: #fff; */
  /* text-decoration: none; */
  background-color: rgb(210 20 64 / 12%) !important;
}
.dropdown-toggle::after {
  display: none !important;
  border: 0 !important;
}
#navbarText {
  font-size: 1.1rem !important;
}
#navbarText li {
  line-height: 1.5rem;
}
.navbar svg {
  font-size: 1.5rem !important;
}
.fas {
  font-size: 4.5rem !important;
  height: auto;
  width: auto;
  color: rgba(250, 250, 250, 0.4);
}
.login-btn {
  background: linear-gradient(45deg, #fe8b32, #f64e79);
  border-radius: 50px;
  margin: 0 0.2rem;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  overflow: hidden;
  width: 110px;
}
.login-btn a span {
  color: #f6f1f2;
}
.login-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(242, 134, 54, 1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.login-btn:hover,
.login-btn:focus,
.login-btn:active {
  color: white;
}
.login-btn:hover:before,
.login-btn:focus:before,
.login-btn:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.float-menu {
  position: fixed;
  bottom: 1.4rem;
  left: 1rem;
  width: 50px;
  height: 50px;
  border-radius: 50% 50% 50% 50%;
  background: #b11a4b;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 800;
}

.float-menu span {
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  transition: 0.3s ease-in-out;
  color: #f6f1f2;
}

.float-menu span:nth-child(2) {
  opacity: 0;
}

.float-menu.active span {
  transform: rotate(135deg);
}

.float-menu.active span:nth-child(2) {
  opacity: 1;
}

.float-menu.active span:nth-child(1) {
  opacity: 0;
}

.float-menu ul {
  position: absolute;
  bottom: 1rem;
  background: #fff;
  min-width: 150px;
  padding: 20px 0;
  border-radius: 25px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.float-menu ul li {
  padding: 10px 20px;
  font-size: 1.2rem;
  text-align: left;
}

.float-menu.active ul {
  bottom: 4rem;
  opacity: 1;
  visibility: visible;
}

.float-menu.active ul li:hover {
  background: #f8f9fa;
}

.float-menu ul li a {
  width: 100%;
}
</style>
