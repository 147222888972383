import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login'
import Register from '@/components/complement/Register'
import Invoice from '@/components/proveedor/Invoice'
import List from '@/components/invoices/List'
import Auth from '@/components/auth/SetPassword'
import Invite from '@/components/auth/Invite'

import ProviderList from '@/components/complement/List'
import FiledList from '@/components/proveedor/List'


import Dashboard from '@/components/start/Dashboard'
import ManageUsers from '@/components/users/Manage'
import CreateUsers from '@/components/users/Create'
import ManageCompanies from '@/components/companies/Manage'
import CreateCompanies from '@/components/companies/Create'
// import Storage from "../main";
import store from '../store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: Register,
    beforeEnter: (to, from, next) => {
      if(to.query.t){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/invite',
    name: 'invite',
    component: Invite,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: Invoice,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'PRO' ){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/invoice/list',
    name: 'invoices-list',
    component: FiledList,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'PRO' ){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/providers',
    name: 'providers',
    component: ProviderList,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM' ){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/list',
    name: 'list',
    component: List,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM' || store.state.userRole == 'CAR'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/companies',
    name: 'companies',
    component: ManageCompanies,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/companies/create',
    name: 'companies-create',
    component: CreateCompanies,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/users',
    name: 'users',
    component: ManageUsers,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: CreateUsers,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if(store.state.userRole == 'COM' || store.state.userRole == 'ADM'){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },
  {
    path: '/set-account',
    name: 'set-account',
    component: Auth,
    beforeEnter: (to, from, next) =>  {
      if(to.query.t){
        next()
      }else{
        next({name: 'login'})
      }
    }
  },  
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let expiresIn = 0;
  if    (store.state.expiration){
        const now = new Date();
        expiresIn =(new Date(store.state.expiration)).getTime()-now.getTime();
  }
  if (!store.state.isAuthenticated && expiresIn <= 0) {
      if (to.name!='login' && to.name!='set-account' && to.name!='registration') {
          next({ name: 'login' })
      }
  }
  next()
})

export default router
