<template>
  <div>
    <b-row>
      <b-container>
        <h1 class="text-center py-2">
          {{labels.title}}
        </h1>
      </b-container>
    </b-row>
    <b-row v-if="!isLoading">
      <b-container>
        <b-col md="10" class="mx-auto"> 
          <b-button @click="goToCreateUser()" id="create-btn">{{
              labels.create
            }}</b-button>
        </b-col>
      </b-container>
      <b-container v-if="!isEmpty" class="pb-5">
        <h4 class="text-center">
          {{labels.info}}
        </h4>
        <b-col md="12" class="mx-auto">
        <md-table
          v-model="searched"
          :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder"
          :md-sort-fn="customSort"
          md-card md-fixed-header
        >
          <md-table-toolbar>
            <md-field md-clearable class="md-toolbar-section-start">
              <md-input
                :placeholder="labels.search"
                v-model="search"
                @input="searchOnTable"
              />
            </md-field>
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }" @click="showInfo(item.id)">
            <md-table-cell :md-label="labels.createdAt" md-sort-by="createdAt">{{
              item.createdAt
            }}</md-table-cell>
            <md-table-cell :md-label="labels.name" md-sort-by="username">{{
              item.username
            }}</md-table-cell>
            <md-table-cell :md-label="labels.email" md-sort-by="email">{{
              item.email
            }}</md-table-cell>
            <md-table-cell
                :md-label="labels.status"
                md-sort-by="enabled"
                >
                <span v-if="item.enabled > 0" class="policy-active">{{labels.active}}</span>
                <span v-else class="policy-pending">{{labels.inactive}}</span>
                </md-table-cell>
          </md-table-row>
        </md-table>
  </b-col>
        <b-col
          md="6"
          class="mx-auto empty-table pt-4"
          v-if="searched.length == 0 && users.length > 0 && !isLoading"
        >
          <h3>{{labels.notFound}}</h3>
          <p>{{labels.notFoundMessage}}: <span>{{ search }}</span></p>
        </b-col>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div class="success-svg mb-4" :class="{ active: isEmpty }">
            <img src="../../assets/imgs/file-2.svg" alt="warning" />
          </div>
        </b-col>
        <b-col md="12" class="justify-content-around">
          <span id="message" v-if="isEmpty">{{labels.empty}}</span>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>{{labels.information}}</md-dialog-title>
      <md-dialog-content>
          <b-row>
            <b-col>
              <h5>{{labels.companyId}}:</h5>
            </b-col>
            <b-col>
              <h5>{{dialogItem.companyName}}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5>{{labels.name}}:</h5>
            </b-col>
            <b-col>
              <h5>{{dialogItem.username}}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5>{{labels.email}}:</h5>
            </b-col>
            <b-col>
              <h5>{{dialogItem.email}}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5>{{labels.idNumber}}:</h5>
            </b-col>
            <b-col>
              <h5>{{dialogItem.idNumber}}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5>{{labels.phonenumber}}</h5>
            </b-col>
            <b-col>
              <h5>{{dialogItem.phone}}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5>{{labels.role}}</h5>
            </b-col>
            <b-col>
              <h5>{{dialogItem.role}}</h5>
            </b-col>
          </b-row>
         
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-accent" @click="showDialog = false">Cerrar</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import User from "@/services/User";
import Company from "@/services/Company";
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByLocated = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.username).includes(toLower(term))
    );
  }

  return items;
};

export default {
  name: "List",

  data: () => ({
    currentSort: "createdAt",
    currentSortOrder: "asc",
    showDialog: false,
    dialogItem: {},
    companies: [],
    users: [],
    searched: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    success: false,
    error: false,
    filename: "List.xls"
  }),
  computed:{
    labels(){
      if (this.$store.state._language == "es-ES") {
        return {
          title: "Administracion de Usuarios",
          subtitle: "Instrucciones",
          instructive: ["Bienvenid@ a la plataforma para la visualización y creación de usuarios"],
          info: "Información General",
          search: "Buscar por Nombre de Usuario",
          information: "Información del Usuario",
          tapLabel: "Datos",
          createdAt: "Fecha Creación",
          name: "Nombre del usuario",
          email: "Correo Electrónico",
          companyId: "Compañia",
          phonenumber: "Teléfono de contacto",
          idNumber: "Número de identificación",
          role: "Rol del Usuario",
          empty: "Aún no cuentas con usuarios",
          create: "Crear",
          active: "Activo",
          inactive: "Inactivo",
          status: "Estado"
        }
      }else {
        return {
          title: "Invoice Visualization Platform",
          subtitle: "Instructions",
          instructive: ["Welcome to the platform for the visualization and creation of users."],
          info: "General Information",
          search: "Search by User Name",
          information: "Información del Usuario",
          tapLabel: "Datos",
          createdAt: "Created Date",
          name: "User Name",
          email: "Email",
          companyId: "Company",
          phonenumber: "Contact Number",
          idNumber: "Identification Id",
          role: "Role",
          empty: "You don't have users yet",
          create: "Create",
          active: "Activo",
          inactive: "Inactive",
          status: "Status"
        }
      }
    }
  },
  methods: {
    goToCreateUser(){
      this.$router.push({path: '/users/create'})
    },
    showInfo(id) {
      const currentIdx = this.users.findIndex((obj) => obj.id == id);
      this.dialogItem = this.users[currentIdx];
      const currentIdxC = this.companies.findIndex((obj) => obj.id == this.dialogItem.companyId);
      this.dialogItem.companyName=this.companies[currentIdxC].name
      this.showDialog = true
    },
    deleteUser(id) {
      this.isLoading = true;
      User.deleteUser(id).then(() => {
        this.isLoading = false;
      });
      this.users = this.users.filter( user => user.id != id );
      this.searched = this.users
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }

        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    searchOnTable() {
      this.searched = searchByLocated(this.users, this.search);
    },
  },
  mounted() {
    this.isLoading = true;
    User.getAllUsers(this.$store.state.userName)
      .then((result) => {
        this.users = result.data.users;
        this.searched = this.users;
        if (this.users.length == 0) {
          this.isEmpty = true;
        }
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        if (this.$store.state.userRole == "ADM") {
           this.users = this.users.filter( user => user.companyId == this.$store.state.companyId );
           this.searched = this.users
        }
        this.isLoading = false;
      });
    Company.getAllCompanies()
      .then((result) => {
        this.companies = result.data.companies;
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style src="../main.css">
</style>
