<template>
  <div>
    <b-row>
      <b-container>
        <h1 class="text-center py-2">
          {{labels.title}}
        </h1>
      </b-container>
    </b-row>
    <b-row v-if="!isLoading">
      <b-container>
        <b-col md="10" class="mx-auto"> 
          <b-button @click="goToCreateCompany()" id="create-btn">{{
              labels.create
            }}</b-button>
        </b-col>
      </b-container>
      <b-container v-if="!isEmpty" class="pb-5">
        <h4 class="text-center">
          {{labels.info}}
        </h4>
        <b-col md="12" class="mx-auto">
        <md-table
          v-model="searched"
          :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder"
          :md-sort-fn="customSort"
          md-card
        >
          <md-table-toolbar>
            <md-field md-clearable class="md-toolbar-section-start">
              <md-input
                :placeholder="labels.search"
                v-model="search"
                @input="searchOnTable"
              />
            </md-field>
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="labels.createdDate" md-sort-by="createdAt">{{
              item.createdAt
            }}</md-table-cell>
            <md-table-cell :md-label="labels.name" md-sort-by="name">{{
              item.name
            }}</md-table-cell>
            <md-table-cell :md-label="labels.email" md-sort-by="email">{{
              item.email
            }}</md-table-cell>
          </md-table-row>
        </md-table>
  </b-col>
        <b-col
          md="6"
          class="mx-auto empty-table pt-4"
          v-if="searched.length == 0 && companies.length > 0 && !isLoading"
        >
          <h3>{{labels.notFound}}</h3>
          <p>{{labels.notFoundMessage}}: <span>{{ search }}</span></p>
        </b-col>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div class="success-svg mb-4" :class="{ active: isEmpty }">
            <img src="../../assets/imgs/file-2.svg" alt="warning" />
          </div>
        </b-col>
        <b-col md="12" class="justify-content-around">
          <span id="message" v-if="isEmpty">{{labels.empty}}</span>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Company from "@/services/Company";
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByLocated = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.name).includes(toLower(term))
    );
  }

  return items;
};
export default {
  name: "List",

  data: () => ({
    currentSort: "createdAt",
    currentSortOrder: "asc",
    companies: [],
    searched: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    success: false,
    error: false,
    filename: "List.xls"
  }),
  computed:{
    labels(){
      if (this.$store.state._language == "es-ES") {
        return {
          title: "Administracion de compañias",
          subtitle: "Instrucciones",
          instructive: ["Bienvenid@ a la plataforma para la visualización y creación de compañias"],
          info: "Información General",
          search: "Buscar por nombre",
          createdDate: "Fecha Creación",
          name: "Nombre de la Compañia",
          email: "Correo Electrónico",
          empty: "Aún no cuentas con empresas",
          create: "Crear",
          notFound: "No Hay compañias",
          notFoundMessage: "No hay compañias con ese nombre"
        }
      }else {
        return {
          title: "Companies Management Platform",
          subtitle: "Instructions",
          instructive: ["Welcome to the platform for the visualization and creation of companies"],
          info: "General Information",
          search: "Search by name",
          createdDate: "Created Date",
          name: "Company Name",
          email: "Email",
          empty: "You don't have invoices yet",
          create: "Create",
          notFound: "Not Found",
          notFoundMessage: "No companies found with that name"
        }
      }
    }
  },
  methods: {
    goToCreateCompany(){
      this.$router.push({path: '/companies/create'})
    },
    deleteCompany(id) {
      this.isLoading = true;
      Company.deleteCompany(id).then(() => {
        this.isLoading = false;
      });
      this.companies = this.companies.filter( company => company.id != id );
      this.searched = this.companies
    },
    customSort(value) {
      // console.log(value);
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }

        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    searchOnTable() {
      this.searched = searchByLocated(this.companies, this.search);
    },
  },
  mounted() {
    this.isLoading = true;
    Company.getAllCompanies(this.$store.state.userName)
      .then((result) => {
        // console.log(result.data)
        this.companies = result.data.companies;
        this.searched = this.companies;
        if (this.companies.length == 0) {
          this.isEmpty = true;
        }
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style src="../main.css">
</style>
