<template>
  <div>
    <b-row>
      <b-container>
        <h1 class="text-center py-2">
          {{labels.title}}
        </h1>
      </b-container>
    </b-row>
    <b-row v-if="!isLoading">
      <b-container v-if="!success && !error">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <h4 class="text-center border-bottom">
            {{labels.invoice}}
          </h4>

          <b-col md="8" lg="6" class="p-4 mx-auto">
            <b-form-group>
               <md-field v-if="$store.state.userRole == 'COM'">
              <md-select v-model="invoice.companyId" :placeholder="labels.companyName">
                <md-option v-for="item in companies" :value="item.id" :key="item.id">{{item.name}}</md-option>
              </md-select>
            </md-field>
            <md-field v-if="$store.state.userRole == 'COM' || $store.state.userRole == 'ADM' ">
              <md-select v-model="invoice.inscriptionId" :placeholder="labels.providerName">
                <md-option v-for="item in providers" :value="item.id" :key="item.id">{{item.name}}</md-option>
              </md-select>
            </md-field>
              <md-field :class="{'md-invalid': numberError}">
                <label>{{labels.number}} </label>
                <md-input v-model="invoice.number" type="number" required @mouseleave="invoiceNumberExists(invoice.number)"></md-input>
                <span class="md-error" v-if="numberError">{{labels.numberError}}</span>
              </md-field>
              <md-datepicker v-model="invoice.date" required>
                <label>{{labels.date}} </label>
              </md-datepicker>
              <md-field>
                <label for="currency">{{labels.currency}} </label>
                <md-select
                        v-model="invoice.currency"
                        name="currency"
                        id="currency"
                        required
                      >
                        <md-option value="USD">USD</md-option>
                        <md-option value="COP">COP</md-option>
                      </md-select>
              </md-field>
              <md-field>
                <label>{{labels.beforeTax}} </label>
                <span class="md-prefix">$</span>
                <md-input v-model="invoice.beforeTax" type="number" required></md-input>
              </md-field>
              <md-field>
                <label>{{labels.iva}} </label>
                <span class="md-prefix">$</span>
                <md-input v-model="invoice.ivaTax" type="number" required></md-input>
              </md-field>
              <md-field>
                <label>{{labels.impo}} </label>
                <span class="md-prefix">$</span>
                <md-input
                  v-model="invoice.impoconsumo"
                  type="number" required
                ></md-input>
              </md-field>
              <md-field>
                <label>{{labels.otherTax}} </label>
                <span class="md-prefix">$</span>
                <md-input v-model="invoice.otherTax" type="number"></md-input>
              </md-field>
            </b-form-group>

            <!-- <b-button type="submit" id="login-btn">Ingresar</b-button> -->
          </b-col>
          <h4 class="text-center border-bottom">
            {{labels.retainer}} 
          </h4>
          <b-col md="8" lg="6" class="p-4 mx-auto">
            <b-form-group>
              <md-field>
                <label>{{labels.reteFuente}} </label>
                <span class="md-prefix">$</span>
                <md-input v-model="invoice.reteFuente" type="number" ></md-input>
              </md-field>
              <md-field>
                <label>{{labels.reteICA}} </label>
                <span class="md-prefix">$</span>
                <md-input v-model="invoice.reteICA" type="number" ></md-input>
              </md-field>
              <md-field>
                <label>{{labels.reteIVA}} </label>
                <span class="md-prefix">$</span>
                <md-input v-model="invoice.reteIVA" type="number" ></md-input>
              </md-field>
              <md-field>
                <label>{{labels.doc}} </label>
                <!-- <input type="file"  ref="file" @change="selectFile()" accept="application/pdf"> -->
                <md-file v-model="invoice.file.name" accept="application/pdf" @md-change="selectFile($event)" required/>
              </md-field>
            </b-form-group>
          </b-col>
          <h4 class="text-center border-bottom">
            {{labels.total}} 
          </h4>
          <b-col md="8" lg="6" class="p-4 mx-auto">
            <b-form-group>
              <md-field>
                <label>$ {{ calculateTotal }}</label>
                <!-- <md-input v-model="invoice.total" :value="calculateTotal"></md-input> -->
              </md-field>
            </b-form-group>
            <b-button type="submit" id="signup-btn">{{labels.button}} </b-button>
          </b-col>
        </form>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div
            class="success-svg mb-4"
            :class="{ active: success }"
            v-if="!error"
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 496 496"
              style="enable-background:new 0 0 496 496;"
              xml:space="preserve"
            >
              <path
                d="M248,0C111.033,0,0,111.033,0,248s111.033,248,248,248s248-111.033,248-248C495.841,111.099,384.901,0.159,248,0z
                     M248,480C119.87,480,16,376.13,16,248S119.87,16,248,16s232,103.87,232,232C479.859,376.072,376.072,479.859,248,480z"
                style="fill: #00ad7e"
              />
              <path
                d="M370.344,158.344L208,320.688l-82.344-82.344c-3.178-3.07-8.242-2.982-11.312,0.196c-2.994,3.1-2.994,8.015,0,11.116
                    l88,88c3.124,3.123,8.188,3.123,11.312,0l168-168c3.07-3.178,2.982-8.242-0.196-11.312
                    C378.359,155.35,373.444,155.35,370.344,158.344z"
                style="fill: #00ad7e"
              />
            </svg>
          </div>
          <div class="success-svg mb-4" :class="{ active: error }" v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              id="Capa_2"
              x="0px"
              y="0px"
              viewBox="0 0 52 52"
              style="enable-background:new 0 0 52 52;"
              xml:space="preserve"
            >

              <g id="g6" style="fill:#ee6363;fill-opacity:0.94117647">
                <path
                  d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26   S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
                  id="path2"
                  style="fill-opacity:0.94117647"
                />
                <path
                  d="M35.707,16.293c-0.391-0.391-1.023-0.391-1.414,0L26,24.586l-8.293-8.293c-0.391-0.391-1.023-0.391-1.414,0   s-0.391,1.023,0,1.414L24.586,26l-8.293,8.293c-0.391,0.391-0.391,1.023,0,1.414C16.488,35.902,16.744,36,17,36   s0.512-0.098,0.707-0.293L26,27.414l8.293,8.293C34.488,35.902,34.744,36,35,36s0.512-0.098,0.707-0.293   c0.391-0.391,0.391-1.023,0-1.414L27.414,26l8.293-8.293C36.098,17.316,36.098,16.684,35.707,16.293z"
                  id="path4"
                  style="fill-opacity:0.94117647"
                />
              </g>
            </svg>
          </div>
        </b-col>
          <h4 class="text-center border-bottom" v-if="success">
            {{labels.located}} : {{code}}
          </h4>
        <b-col md="12" class="justify-content-around">
          <span type="button" id="reset-btn" v-if="success" @click="resetForm()"
            >{{labels.new}} </span
          >
          <span type="button" id="check-btn" v-if="error" @click="checkForm()"
            >{{labels.validate}} </span
          >
          <span type="button" id="resend-btn" v-if="error" @click="submitForm()"
            >{{labels.try}} </span
          >
        </b-col>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner
          ></b-spinner>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Invoice from "@/services/Invoice";
import Company from "@/services/Company";
import Provider from "@/services/Provider";

export default {
  name: 'Invoice',
  data: () => ({
    invoice: {
      companyId: "",
      inscriptionId: "",
      number: "",
      date: "",
      currency: "",
      beforeTax: "",
      ivaTax: "",
      impoconsumo: "",
      otherTax: "",
      reteFuente: "",
      reteICA: "",
      reteIVA: "",
      total: "",
      file: {name: ""},
    },
    // labels: "",
    code: null,
    isLoading: false,
    success: false,
    error: false,
    companies: [],
    providers: [],
    numberError: false

    // language: ""
  }),
  methods: {
    invoiceNumberExists(number) {
      let currentProvider = null;
      this.isLoading = true;
      if(this.$store.state.userRole == "COM" || this.$store.state.userRole == "ADM"){
        currentProvider = this.invoice.inscriptionId
      }else{
        currentProvider = this.$store.state.providerId
      }
      if(number && currentProvider){
        Invoice.getSingle(number, currentProvider)
        .then(() => {
          this.numberError = false
        })
        .catch(() => {
          this.numberError = true
        })
      }
      this.isLoading = false
    },
    submitForm: function() {
      this.isLoading = true;
      if(!this.numberError){

      this.invoice.total =
        Number(this.invoice.beforeTax) +
        Number(this.invoice.ivaTax) +
        Number(this.invoice.impoconsumo) +
        Number(this.invoice.otherTax) -
        Number(this.invoice.reteFuente) -
        Number(this.invoice.reteICA) -
        Number(this.invoice.reteIVA);
      let formData = new FormData();
      if(this.$store.state.userRole == 'COM'){
        formData.append('companyId', this.invoice.companyId);
        formData.append('inscriptionId', this.invoice.providerId);
      }else if(this.$store.state.userRole == 'ADM'){
        formData.append('inscriptionId', this.invoice.providerId);
        formData.append('companyId', this.$store.state.companyId);
      }
      else{
      formData.append('companyId', this.$store.state.companyId);
      formData.append('inscriptionId', this.$store.state.providerId);
      }
      formData.append('file', this.invoice.file);
      formData.append('number', this.invoice.number);
      formData.append('date',this.invoice.date );
      formData.append('currency',this.invoice.currency );
      formData.append('beforeTax',this.invoice.beforeTax );
      formData.append('ivaTax',this.invoice.ivaTax );
      formData.append('impoconsumo',this.invoice.impoconsumo );
      formData.append('otherTax',this.invoice.otherTax );
      formData.append('reteFuente',this.invoice.reteFuente );
      formData.append('reteICA',this.invoice.reteICA );
      formData.append('reteIVA',this.invoice.reteIVA );
      formData.append('total',this.invoice.total );
      formData.append('provider', this.$store.state.userName );
      formData.append('language', this.$store.state._language)

      Invoice.send(formData)
        .then((created) => {
          this.success = true;
          this.error = false;
          this.code = created.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
        }else{
        this.isLoading = false;
      }
    },
    resetForm: function() {
      this.invoice.number = "";
      this.invoice.date = "";
      this.invoice.currency = "";
      this.invoice.beforeTax = "";
      this.invoice.ivaTax = "";
      this.invoice.impoconsumo = "";
      this.invoice.otherTax = "";
      this.invoice.reteFuente = "";
      this.invoice.reteICA = "";
      this.invoice.reteIVA = "";
      this.invoice.total = "";
      this.invoice.file = {name: ""};
      this.code = null;
      this.success = false;
      this.error = false;
    },
    checkForm: function() {
      this.error = false;
    },
    selectFile: function(evt){
      this.invoice.file = evt[0];
    },
  },
  computed: {
    calculateTotal() {    
        return (
          Number(this.invoice.beforeTax) +
          Number(this.invoice.ivaTax) +
          Number(this.invoice.impoconsumo) +
          Number(this.invoice.otherTax) -
          Number(this.invoice.reteFuente) -
          Number(this.invoice.reteICA) -
          Number(this.invoice.reteIVA)
        )
  },
    labels(){
      if (this.$store.state._language == "es-ES") {
       return {
        title: "Radicación De Facturas",
        subtitle: "Instrucciones",
        instructive: ["Bienvenidos a la plataforma de radicación de facturas de PRUEBA FACTURAS, lo invitamos a diligenciar totalmente la información solicitada de su factura y adjuntar el respectivo documento."],
        invoice: "Información de la Factura",
        number: "Número de Factura",
        date: "Seleccione la Fecha",
        currency: 'Moneda',
        beforeTax: "Valor Antes de Impuesto",
        iva: "IVA Facturado",
        impo: "Impoconsumo",
        otherTax: "Otros Impuestos",
        retainer: "Retenciones",
        reteFuente: "Retención en la Fuente",
        reteICA: "Retención de Industria y Comercio",
        reteIVA: "Retención de IVA",
        doc: "Soporte",
        total: "Total a Pagar",
        button: "Radicar",
        validate: "Revisar Formulario",
        new: "Diligenciar nuevo formulario",
        located: "Número de Radicado",
        try: "Volver a Intentar",
        companyName: "Compañia",
        providerName: "Proveedor",
        numberError: "Ya has registrado una factura con ese número",

      };
      } else {
        return {
        title: "Invoices Registration Platform",
        subtitle: "Instructions",
        instructive: ["Welcome to PRUEBA FACTURAS's Invoice Filing Platform, we invite you to fill out the requested information on your invoice and attach the respective document."],
        invoice: "Invoice Information",
        number: "invoice Number",
        date: "Pick Invoice Date",
        currency: 'Currency',
        beforeTax: "Value before taxes",
        iva: "IVA",
        impo: "Impoconsumo",
        otherTax: "Other Taxes",
        retainer: "Withholdings",
        reteFuente: "Retención en la Fuente",
        reteICA: "Retención de Industria y Comercio",
        reteIVA: "Retención de IVA",
        doc: "Document",
        total: "Total Value",
        button: "Settle",
        validate: "Check Invoice Form",
        new: "Sign New Invoice",
        located: "Settle Number",
        try: "Try Again",
        companyName: "Company Name",
        providerName: "Provider Name",
        numberError: "Already registered invoice number",

      };
      }
    }
  },
  mounted(){
    if(this.$store.state.userRole == "COM" ){
    this.isLoading = true;
    Company.getAllCompanies()
      .then((result) => {
        // console.log(result.data)
        this.companies = result.data.companies;
        if (this.companies.length == 0) {
          this.isEmpty = true;
        }
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      })
      .finally(() => {
        
      });
    Provider.getAll()
      .then((result) => {
        // console.log(result.data)
        this.providers = result.data.inscriptions;
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
    }
    if(this.$store.state.userRole == "ADM"){
    this.isLoading = true;
    Provider.getAllByCompany(this.$store.state.companyId)
      .then((result) => {
        // console.log(result.data)
        this.providers = result.data.inscriptions;
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
    }
  }
  };
</script>

<style src="../main.css">
form {
  width: 100%;
}
</style>
